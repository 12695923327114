export const colorBag = (value) => {
    const statusBags = {
       'Preta':  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-white bg-black rounded-lg bg-opacity-75">
          preta
       </span>,
       'Champagne': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-black bg-yellow-100 rounded-lg bg-opacity-50">
          Champagne
       </span>
    }
      
    return statusBags[value] 
 }