import axios from 'axios'
import moment from 'moment';
import config from '../../config.json'

const api = axios.create({
	baseURL: config.back_url
})

api.interceptors.request.use(async(config) => {

   const expiry = localStorage.getItem("expiry_date")
   var now = moment().format()

	if(expiry < now){
      localStorage.clear();
      window.location.href = '/'
   } 
   
	return config;
}, (error) => {

   return Promise.reject(JSON.stringify(error));
});

export default api

