import { payment } from "./renderPayment"

export const statusPayment = (pay, status) => {
   const statusPay = {
      'approved': <span className="flex py-1.5 w-[110px] justify-center text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
         {payment(pay)} &nbsp; Aprovado
      </span>,
         
      'waiting_payment': <span className="flex py-1.5 w-[110px] justify-center text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
         {payment(pay)} &nbsp; Aguardando
      </span>,
         
      'reproved': <span className="flex py-1.5 w-[110px] justify-center text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
         {payment(pay)} &nbsp; Não pago
      </span>
   }
      
   return statusPay[status]
}