import api from './Api'

function validToken () {
	const Token = localStorage.getItem("token")
	return Token
}

class HelpersCollaborators {
   async ListCollaborators(idBranch) {
		return new Promise(async(resolve, reject) => {
		   try {
			   	const collaborators = await api.get('admin/branch/login/list?branch_id='+idBranch, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  		'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (collaborators)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async LitsCollaboratorsBranch() {
		return new Promise(async(resolve, reject) => {
		   try {
			   	const collaborators = await api.get('/branch/user?profile=branch', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  		'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (collaborators)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	// Criar função para editar colaborador de branch

   async EditColaborator(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   	const collaborators = await api.put('/admin/user/'+values.id, values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (collaborators)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async LitsAdmins() {
		return new Promise(async(resolve, reject) => {
		   try {
			   	const collaborators = await api.get('/admin/user/getAll?profile=admin', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  		'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (collaborators)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async DeleteCollaboratorBranch(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   	const collaborators = await api.delete('/branch/user/'+id, {
					headers: {
					   	'Content-Type': 'application/json',
					   	'Access-Control-Allow-Origin' : '*',
                  		'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (collaborators)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

}

export { HelpersCollaborators };