import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from "react-router-dom";

// Estilização Grid Propostas
import '../../components/components.css'

import PopUpHeader from '../../components/popUpHeader'

import Sucess from '../../components/sucess';

import FormColaborators from './components/formColaborators'

import { HelpersAuth, HelpersCollaborators } from '../../../../../helpers/index'
const helpersAuth = new HelpersAuth()
const helpersCollaborators = new HelpersCollaborators()


function Branchs({ branchs, branch, setBranch }) {
   const [open, setOpen] = useState(false);
   const [colaborators, setColaborators] = useState([])
   const [colaborator, setColaborator] = useState({})
   const [step, setStep] = useState('edit');
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false)
   const [error, setError] = useState('')
   let idBranchs = branchs.map((e) => {return e.id})

   const navigate = useNavigate();

   useEffect(() => {
      const preListColaborator = () => {
         helpersCollaborators.ListCollaborators(branch).then(resp => {

            const allLogins = resp.data.data.reduce((acc, item) => {
               return acc.concat(item.logins);
            }, []);

            Promise.all(allLogins.map(async (item) =>
            ({
               ...item,
               name_branch: idBranchs.includes(item.branch_id) ? branchs.find(el => el.id === item.branch_id).name : 'Un excluída',
            })
            )).then((newAllLogins) => {
               setColaborators(newAllLogins)
               setLoading(false)
            });
         })
      }

      if(branchs.length > 0) preListColaborator()
   }, [branch, branchs])

   const preListColaborator = () => {
      helpersCollaborators.ListCollaborators(branch).then(resp => {

      const allLogins = resp.data.data.reduce((acc, item) => {
         return acc.concat(item.logins);
      }, []);

      Promise.all(allLogins.map(async (item) =>
         ({
            ...item,
            name_branch: idBranchs.includes(item.branch_id) ? branchs.find(el => el.id === item.branch_id).name : 'Un excluída',
         })
         )).then((newAllLogins) => {
            setColaborators(newAllLogins)
            setLoading(false)
         });
      })
   }

   const createCollaborator = (values) => {
      setSaveLoading(true)
      helpersAuth.CreateUser(values).then(() => {
         setSaveLoading(false)
         setStep('sucess')
      }).catch((error) => {
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      })
   }

   const updateCollaborator = (values) => {
      setSaveLoading(true)
      try {
         helpersCollaborators.EditColaborator(values)
         setSaveLoading(false)
         setStep('sucess')
      } catch (error) {
         setSaveLoading(true)
      }
   }

   const ModalAdd = () => {
      if (!open) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
            if (step === 'sucess') preListColaborator()
            setColaborator({})
            setOpen(false);
            navigate('/admin/panel/collaborators')
         }
      }

      const renderScreen = () => {
         switch (step) {
            case 'edit':
               return <FormColaborators
                  header={<PopUpHeader title={'Editar Colaborador'} subTitle={'Preencha os dados do colaborador da unidade'} />}
                  editSaveColaborator={values => updateCollaborator(values)}
                  saveLoading={saveLoading}
                  bag={branch}
                  colaborator={colaborator}
                  error={error}
                  readOnly={true}
               />
            case 'add':
               return <FormColaborators
                  header={<PopUpHeader title={'Cadastrar colaborador'} subTitle={'Preencha os dados do colaborador da unidade'} />}
                  editSaveColaborator={(values) => createCollaborator(values)}
                  saveLoading={saveLoading}
                  bag={branch}
                  colaborator={colaborator}
                  error={error}
                  readOnly={false}
               />

            case 'sucess':
               return <Sucess
                  close={() => {
                     preListColaborator()
                     setOpen(false);
                     navigate('/admin/panel/collaborators')
                  }}
               >Colaborador criada/atualizada com sucesso</Sucess>

            default:
               break;
         }
      }

      return (
         <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0"
                  onClick={() => {
                     if (step === 'sucess') preListColaborator()
                     setColaborator({})
                     setOpen(false);
                     navigate('/admin/panel/collaborators')
                  }}>
                  X
               </button>
               <div className="bg-white p-2 rounded">
                  {renderScreen()}
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
         </tr>
      )
   }

   return (
      <div className='px-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Colaboradores</h2>
            <button
               href="#"
               onClick={() => {
                  setOpen(true)
                  setStep("add")
               }}
               className="h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-orange text-white
               rounded-2xl flex justify-center items-center px-5"
            >
               Adicionar colaborador
            </button>
         </div>
         <div className="flex flex-col mb-5">
            <label className="text-xs font-medium uppercase mb-1">Selecione a unidade:</label>
            <select
               name="color"
               className="border border-gray-300 
                  text-sm rounded outline-none
                  focus:border-black  w-full p-2"
               autoComplete="nope"
               required
               value={branch}
               onChange={(e) => setBranch(e.target.value)}
            >
               <option value="">TODAS AS UNIDADES</option>
               {
                  branchs.map((item, index) => {
                     return (
                        <option value={item.id} key={index.toString()}>{item.name} - {item.state_name}</option>
                     )
                  })
               }
            </select>
         </div>
         <div className='overflow-y-scroll rounded-lg shadow h-[80%]'>
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>UNIDADE</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EMAIL</th>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EDITAR</th>
                  </tr>
               </thead>

               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ? (rows.map((item) => {
                        return (item)
                     })) : (colaborators.map((item, index) => {
                        return (
                           <tr className='bg-white' key={index}>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                 {item.id}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                 {item.name_branch}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                 {item.name}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 {item.email}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 <button
                                    onClick={() => {
                                       setColaborator(item);
                                       setStep('edit')
                                       setOpen(true);
                                       navigate(`/admin/panel/collaborators/${item.id}`)
                                    }}
                                    className="font-bold text-blue-500 hover:underline"
                                 >
                                    Editar
                                 </button>
                              </td>
                           </tr>
                        )
                     }))
                  }
               </tbody>
            </table>
         </div>
         <ModalAdd />
      </div>
   )
}

export default Branchs