import React, { useState } from 'react'
import moment from 'moment';

function Step1({ header, editSaveBag, saveLoading, branch, error, bag }) {
   const [brand, setBrand] = useState(bag.brand);
   const [size, setSize] = useState(bag.bag_size_id);
   const [purchaseDate, setPurchaseDate] = useState(bag.buy_at);
   const [model, setModel] = useState(bag.model)
   const [obs, setObs] = useState(bag.observations)
   const [color, setColor] = useState(bag.color)
   const [status, setStatus] = useState(bag.status)


   const next = (e) => {
      e.preventDefault();
      let data = {
         bag_size_id: size,
         branch_id: branch,
         brand: brand,
         model: model,
         buy_at: moment(purchaseDate).format('DD/MM/YYYY'),
         color: color,
         observations: obs
      }
      editSaveBag(data)
   }

   return (
      <form autoComplete="off" className='w-[300px] lg:w-[600px] xl:w-[1000px]' onSubmit={next}>
         {header}
         <section className="flex flex-row">
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">SKU<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="Marca"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                        autoComplete="nope"
                        placeholder='Marca da mala'
                        maxLength="100"
                        required
                        value={bag.identification}
                        readOnly
                     />
                  </div>
               </div>
                <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Modelo<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="modelo"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        placeholder='Modelo da mala'
                        required
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Data de compra<span className="text-red-600">*</span></label>
                     <input
                        type="date"
                        name="purchaseDate"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        placeholder='Data de compra'
                        maxLength="100"
                        required
                        value={purchaseDate}
                        onChange={(e) => setPurchaseDate(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Cor<span className="text-red-600">*</span></label>
                     <select
                        name="color"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        required
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                     >
                        <option value="Preta">Preta</option>
                        <option value="Champagne">Champagne</option>
                     </select>
                  </div>
               </div>

            </div>
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Marca<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="Marca"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                        autoComplete="nope"
                        placeholder='Marca da mala'
                        maxLength="100"
                        required
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Tamanho<span className="text-red-600">*</span></label>
                     <select
                        name="status"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                        autoComplete="nope"
                        required
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                     >
                        <option value="1">P</option>
                        <option value="2">M</option>
                        <option value="3">G</option>
                     </select>
                  </div>
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Status<span className="text-red-600">*</span></label>
                     <select
                        name="color"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        required
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                     >
                        <option value="available">Disponível</option>
                        <option value="in_use">Em uso</option>
                        <option value="unavaliable">Indisponível</option>
                     </select>
                  </div>
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Observações<span className="text-red-600">*</span></label>
                        <textarea
                           type="text"
                           name="observacoes"
                           className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black w-full p-2"
                           autoComplete="nope"
                           placeholder='Observações'
                           maxLength="200"
                           value={obs}
                           onChange={(e) => setObs(e.target.value)}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {
            error ? <div className='bg-red-300 rounded-lg p-2 mx-3'>
               <p className="text-red-600">
                  Erro ao cadastrar mala
               </p>
            </div> : null
         }
         <section className="flex justify-end p-3">
            <button
               type='submit'
               className="w-24 h-10 text-xs font-bold uppercase 
                  tracking-wider bg-default-blue text-white
                  rounded flex justify-center items-center"
               disabled={saveLoading}
            >
               {saveLoading ? 'Salvando...' : 'Salvar'}
            </button>
         </section>
      </form>
   )
}

export default Step1