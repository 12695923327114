import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    UPDATE_USER
} from './constants';


import {
   loginUserSuccess,
   forgetPasswordSuccess,
   apiError
} from './actions';

import  { HelpersAuth } from '../../helpers'
const helpersAuth = new HelpersAuth()

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* login({ payload: { email, password } }) {
   try {
      const response = yield call(helpersAuth.Login, email, password);
      localStorage.setItem("token", response.data.token)
      var now = moment().add(1, 'hours').format()
      localStorage.setItem("expiry_date", now)
      yield put(loginUserSuccess(response.data.user));   
   } catch (error) {
      yield put(apiError(error));
      yield call(delay, 3000);
      yield put(apiError(null));
   }
}

function* register({ payload: { user } }) {
   try {
      //const response = yield call(fireBaseBackend.registerUser, email, password, username);
      //yield put(registerUserSuccess(response));
    
   } catch (error) {
      yield put(apiError(error));
   }
}

function* updateUser({ payload: { image} }) {
   try {
      //yield call(fireBaseStorageBackend.imageProfile, image );
   } catch (error) {
      yield put(apiError(error));
   }
}

function* logout({ payload: { history } }) {
   try {
      localStorage.removeItem("token");
       
      yield call(() => { history("/")});
   } catch (error) {

   }
}

function* forgetPassword({ payload: { email } }) {
    try {
        yield call(helpersAuth.sendEmailForgot, email);     
        yield put(forgetPasswordSuccess('sendEmail'));
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchUpdateUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
    ]);
}

export default authSaga;