/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from "react-router-dom";
import PopUpHeader from '../../components/popUpHeader'

import AddFormInfluencer from './components/AddFormInfluencer';
import AddFormCupom from './components/AddFormCupom';
import EditFormInfluencer from './components/EditFormInfluencer';
import Exclude from '../../components/exclude';
import Sucess from '../../components/sucess';

import { HelpersInfluencers } from '../../../../../helpers/index'
const helpersInfluencers = new HelpersInfluencers()

function Influencers({states}) {
   const [open, setOpen] = useState(false)
   const [openCupom, setOpenCupom] = useState(false)
   const [influencerId, setInfluencerId] = useState(0)
   const [step, setStep] = useState('add');
   const [error, setError] = useState(false);
   const [loading, setLoading] = useState(false);
   const [saveLoading, setSaveLoading] = useState(false);
   const [influencers, setInfluencers] = useState([]);
   const [influencer, setInfluencer] = useState({});
   const navigate = useNavigate();

   useEffect(() => {
      const listInfluencers = () => {
         helpersInfluencers.ListInfluencer().then(resp  => {
            setInfluencers(resp.data.data)
         })
      }

      listInfluencers()
   }, [])

   const listInfluencers = () => {
      helpersInfluencers.ListInfluencer().then(resp  => {
         setInfluencers(resp.data.data)
      })
   }

   const addInfluencer = (values) => {
      helpersInfluencers.CreateInfluencer(values).then(() => {
         setStep('sucess')
      })
   }



   const editInfleuncer = () => {

   }

   const deleteInfluencer = () => {

   }

   const ModalAdd = () => {
      if (!open) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
            if(step === 'sucess') listInfluencers()
            setInfluencer({})
            setOpen(false);
            navigate('/admin/panel/influencers')
         }
      }

      const renderScreen = () => {
         const steps = {
            'edit': <EditFormInfluencer
               header={<PopUpHeader title={'Editar influencer'} subTitle={'Preencha os dados do influencer'} />}
               editSaveBag={(values) => editInfleuncer(values)}
               saveLoading={saveLoading}
               error={error}
            />,
            'add': <AddFormInfluencer
               header={<PopUpHeader title={'Cadastrar influencer'} subTitle={'Preencha os dados do influencer'} />}
               editSaveInfluencer={(values) => addInfluencer(values)}
               saveLoading={saveLoading}
               states={states}
               error={error}
            />,
            'sucess': <Sucess close={() => {
               listInfluencers()
               setOpen(false);
               navigate('/admin/panel/influencers')
            }}
            >
               Influencer criado/atualizada com sucesso
            </Sucess>,
            'excluir': <Exclude  exclude={() => deleteInfluencer()}>
               Tem certeza que deseja excluir o digital influencer?
            </Exclude>
         }
         return steps[step]
      }


      return (
         <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0"
                  onClick={() => {
                     if(step === 'sucess') listInfluencers()
                     setInfluencer({})
                     setOpen(false);
                     navigate('/admin/panel/influencers')
                  }}>
                  X
               </button>
               <div className="bg-white p-2 rounded">
                  {renderScreen()}
               </div>
            </div>
         </div>
      )
   }

   const ModalAddCupom = () => {
      if (!openCupom) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
   
         }
      }

      return (
         <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0" onClick={() => setOpenCupom(false)}>
                  X
               </button>
               <div className="bg-white p-2 rounded">
                  <AddFormCupom id={influencerId} />
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
         </tr>
      )
   }

   return (
      <div className='pl-5 pr-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Digital influencers</h2>
            <a 
               href="#"
               onClick={() => {
                  setOpen(true)
                  setStep('add')
               }}
               className="w-56 h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-orange text-white
               rounded-2xl flex justify-center items-center"
            >
               Adicionar influencer
            </a>
         </div>
         <div className="rounded-lg shadow overflow-auto">
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>INFLUENCER</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>INSTAGRAM</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>TIKTOK</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>WHATSAPP</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>RESERVAS</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>FATURAMENTO</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>REPASSE</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>CUPONS</th>
                  </tr>
               </thead>

               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {  
                     loading ? (rows.map((item) => {
                        return (item)
                     })) : (influencers.map((item, index) => {
                        return (
                           <tr className='bg-white overflow-auto' key={index.toString()}>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                 <p className="font-bold text-blue-500 hover:underline">
                                    {item.id}
                                 </p>
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold ">
                                 {item.name}
                              </td>
                              <td className="flex items-center p-3 text-sm font-semibold text-gray-700 whitespace-nowrap">
                                 {item.instagram}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                                 {item.tiktok}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                                 {item.whatsapp}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 {item.bookings_count_coupon}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 R$ {item.bookings_value_coupon}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 R$ {((item.bookings_value_coupon * 25) / 100).toFixed(2)}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 <button
                                    onClick={() => {
                                       setInfluencerId(item.id)
                                       setOpenCupom(true)}
                                    }
                                    className="font-bold text-blue-500 hover:underline"
                                 >
                                    Abrir
                                 </button>
                              </td>
                           </tr>
                        )
                     }))
                  }
               </tbody>
            </table>
         </div>
         <ModalAdd />
         <ModalAddCupom />
      </div>   
   )
}

export default Influencers