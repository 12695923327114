import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function PaginatedItems({ itemsPerPage, setPg, qtdItems }) {
   // We start with an empty list of items.

   const [pageCount, setPageCount] = useState(1);
   const [pageNumber, setPageNumber] = useState(0);
   // Here we use item offsets; we could also use page offsets
   // following the API or data you're working with.


   useEffect(() => {
      // Fetch items from another resources.
      setPageCount(Math.ceil(qtdItems / itemsPerPage));
      setPageNumber(0)
   }, [itemsPerPage, qtdItems]);

   // Invoke when user click to request another page.
   const handlePageClick = (event) => {
      setPg(event.selected + 1)
      setPageNumber(event.selected)
   };

   return (
      <>
         <ReactPaginate
            className='flex flex-row'
            pageClassName="bg-white border border-gray-300 text-xs text-gray-500  hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            breakClassName="bg-white border border-gray-300 text-xs text-gray-500  hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            activeClassName="bg-blue-50 border border-gray-300 text-xs text-blue-600 font-bold hover:bg-blue-100 hover:text-blue-700  py-2 px-3 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
            previousClassName="bg-white border border-gray-300 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-l-lg leading-tight py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            nextClassName="bg-white border border-gray-300 text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700 rounded-r-lg leading-tight py-2 px-3 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            breakLabel="..."
            nextLabel=">>"
            forcePage={pageNumber}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
         />
      </>
   );
}

export default PaginatedItems