import React, {useEffect, useState} from 'react'
import moment from 'moment';

import { statusPayment } from "../../../../../../components/renderStatusPayment"
import  StatusBooking  from "../../../.././../../components/renderStatusBooking"

import { setCity, bag_sizes } from '../../../../../../utils';

import { HelpersAuth, HelpersAdress } from '../../../../../../helpers/index'
const helpersAuth = new HelpersAuth()
const helpersAdress = new HelpersAdress()

function DetailsBooking({booking, sendBag, receiveBag}) {
   const [user, setUser] = useState({})
   const [filial, setFilial] = useState('')

   useEffect(() => {
      const findUser = async(id) => {
         await helpersAuth.UserB(id).then(resp => {
            setUser(resp.data.data[0])
         })
      }

      const findCity = async() => {
         const city = await helpersAdress.ListCities(booking.state_id, booking.city_id).then(resp => {
            const nome = setCity(resp.data.data, booking.city_id)
            return nome
         })
         setFilial(city)
      } 

      findUser(booking.user_id)
      findCity()
   }, [booking.city_id, booking.state_id, booking.user_id])

   return(
      <div className='w-[300px] lg:w-[600px] xl:w-[1000px]'>
         <header className='h-36 flex flex-row justify-between  items-center p-2'>
            <img src={require("../../../../../../assets/images/malugOrange.png")} alt="img"  width="150"/>
            <div>
               <p className='text-sm'>
               Malug - Aluguel de Malas LTDA
               </p>
               <br/>
               <p className='flex justify-end text-xs'>
                  MA 201, N°: S/N <br/>
                  65110-000 - São José de Ribamar, MA <br/>
                  Loja: Loja Física
               </p>
            </div>
         </header>
         <body>
            <h3 className='text-center my-5'>Ordem de serviço N° {booking.id}</h3>
            <div className="w-full flex flex-row">
               <div className='w-2/5'>
                  <h3>Cliente:</h3>
                  <div className='border border-slate-300 h-36 flex items-center pl-3 mr-2'>
                     <p className='text-sm'>
                        {user.name} <br/>
                        {user.cpf} <br/>
                        {user.street} / {user.complement} <br/>
                        {user.district} <br/>
                        {user.cep} <br/>
                        {user.phone}
                     </p>
                  </div>
               </div>
               <div className='w-3/5 '>
                  <div className='h-36 mt-6 flex flex-row'>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                          Unidade
                        </div>
                        <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                           {filial}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                           Receber
                        </div>
                        <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                           {moment(booking.check_in).format('DD/MM/YY')}
                        </div>
                     </div>
                     <div className="flex flex-col flex-1">
                        <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                           Devolver
                        </div>
                        <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                           {moment(booking.check_out).format('DD/MM/YY')}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='mt-6'>
               <h3>Malas:</h3>
               {
                  booking.bags.map((item, index) => (
                     <div className='mb-3 w-full flex items-center' key={index.toString()}>
                        <div className="w-full">
                           <p className='font-semibold flex'>SKU: &nbsp;<p className='font-bold text-blue-500 hover:underline'>{item.identification}</p></p>
                           <table className='w-full'>
                              <thead className="bg-gray-100">
                                 <tr>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Itens</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Marca / Modelo</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Cor</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Valor Un</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Quantidade de diárias</th>
                                    <th className='p-2 text-sm font-semibold tracking-wide text-left'>Total</th>
                                 </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100">
                                 <tr>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Mala {bag_sizes(item.bag_size_id)}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.brand} - {item.model}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{item.color}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.size_price}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{booking.days}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {(parseFloat(item.size_price) * booking.days).toFixed(2)}</td>
                                 </tr>
                                 <tr>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>Seguro</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'></td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap italic border border-slate-300'>R$ {item.secure_value}</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>{booking.days} (Não se aplica ao cálculo)</td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.secure_value}</td>
                                 </tr>
                                 <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='p-2 text-sm text-gray-700 whitespace-nowrap border border-slate-300'>R$ {item.total}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  ))
               }
            </div>
            <div>
               <h3>Totais:</h3>
               <div className='h-20 flex flex-row'>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                     Total em malas
                     </div>
                     <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                     R${(booking.total_value - booking.delivery_value).toFixed(2)}
                     </div>
                  </div>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                        Entrega/coleta 
                     </div>
                     <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                        R$ {booking.delivery_value}.00
                     </div>
                  </div>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                      Total da ordem de serviço
                     </div>
                     <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                        R$ {booking.total_value}
                     </div>
                  </div>
               </div>
            </div>
            <div className='my-6'>
               <h3>Dados de pagamento:</h3>
               <div className='h-20 flex flex-row'>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0 border-r-0 border border-slate-300 items-center pl-2'>
                        Metodo de pagamento:
                     </div>
                     <div className='flex flex-1 border-r-0 border border-slate-300 items-center pl-2'>
                        {booking.payment_method === 'credit_card' ? 'Cartão de crédito' : null}
                        {booking.payment_method === 'pix' ?  'Pix' : null}
                     </div>
                  </div>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                        Pagamento:
                     </div>
                     <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                        {statusPayment(booking.payment_method, booking.status)}
                     </div>
                  </div>
                  <div className="flex flex-col flex-1">
                     <div className='flex flex-1 border-b-0  border border-slate-300 items-center pl-2'>
                        Status da reserva
                     </div>
                     <div className='flex flex-1 border border-slate-300 items-center pl-2'>
                        <StatusBooking 
                           status={booking.reserve_status}  
                           sendBag={() => sendBag()} 
                           receiveBag={() => receiveBag()} 
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className='mt-15 '>
               <p className='text-xs'>Concordo com os termos acima:</p>
               <div className=' flex flex-row justify-between'>
                  <div>
                     <p>Data: _____ /_____ /______</p>
                  </div>
                  <div> 
                     ________________________________________
                     <p className='text-xs text-center'>Assinatura do responsável</p>
                  </div>
               </div>
               
            </div>
         </body>
      </div>
   )
}

export default DetailsBooking
