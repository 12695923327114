import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}

class HelpersCupons {

   async ListCupons(id) {
      return new Promise(async (resolve, reject) => {
         try {
            const branch = await api.get('/admin/coupon/list?influencer_id='+id, {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
               },
            })

            resolve(branch)
         } catch (error) {
            reject(error)
         }
      })
   }

   async CreateCupons(values) {
      return new Promise(async (resolve, reject) => {
         try {
            const branch = await api.post('/admin/coupon/create', values, {
               headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer ' + validToken()
               },
            })

            resolve(branch)
         } catch (error) {
            reject(error)
         }
      })
   }
}

export { HelpersCupons };