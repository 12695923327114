/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const Sidebar = ({ children, user, screen, logout }) => {
   const [isOpen, setIsOpen] = useState(true);

   const toggle = () => setIsOpen(!isOpen);
   const navigate = useNavigate();
   const logOut = () => { logout() };

   const menuItemAdm = [
      {
         name: "Dashboard",
         label: "dashboard",
         icon: <i className="bi bi-speedometer"></i>,
         action: () => navigate("/admin/panel/dashboard")
      },
      {
         name: "Admins",
         label: "admins",
         icon: <i className="bi bi-person-circle"></i>,
         action: () => navigate("/admin/panel/admins")
      },
      {
         name: "Unidades",
         label: "branchs",
         icon: <i className="bi bi-house"></i>,
         action: (rota) => navigate("/admin/panel/branchs")
      },
      {
         name: "Tamanhos de Malas",
         label: "bags",
         icon: <i className="bi bi-luggage"></i>,
         action: (rota) => navigate("/admin/panel/bags")
      },
      {
         name: "Manual do licenciado",
         label: "manualdolicenciado",
         icon: <i className="bi bi-book"></i>,
         action: (rota) => navigate("/admin/panel/manualdolicenciado")
      },
      {
         name: "Reservas",
         label: "reservas",
         icon: <i className="bi bi-file-earmark"></i>,
         action: (rota) => navigate("/admin/panel/reservas")
      },
      {
         name: "Estoque",
         label: "estoque",
         icon: <i className="bi bi-person-check"></i>,
         action: () => navigate("/admin/panel/estoque")
      },
      {
         name: "Colaboradores",
         label: "collaborators",
         icon: <i className="bi bi-person-check"></i>,
         action: () => navigate("/admin/panel/collaborators")
      },
      {
         name: "Influencers",
         label: "influencers",
         icon: <i className="bi bi-person-check"></i>,
         action: () => navigate("/admin/panel/influencers")
      },
      {
         name: "Logout",
         label: "Logout",
         icon: <i className="bi bi-box-arrow-right"></i>,
         action: () => logOut()
      }
   ]

   const menuItemBra = [
      {
         name: "Dashboard",
         label: "dashboard",
         icon: <i className="bi bi-speedometer"></i>,
         action: () => navigate("/branch/panel/dashboard")
      },
      // {
      //    name:"Notificações",
      //    label: "notifications",
      //    icon: <i className="bi bi-bell"></i>,
      //    action: (rota) => navigate("/branch/panel/notificacoes")
      // },
      {
         name: "Manual do licenciado",
         label: "manualdolicenciado",
         icon: <i className="bi bi-book"></i>,
         action: () => navigate("/branch/panel/manualdolicenciado")
      },
      {
         name: "Reservas",
         label: "reservas",
         icon: <i className="bi bi-file-earmark"></i>,
         action: () => navigate("/branch/panel/reservas")
      },
      {
         name: "Estoque",
         label: "estoque",
         icon: <i className="bi bi-person-check"></i>,
         action: () => navigate("/branch/panel/estoque")
      },
      {
         name: "Colaboradores",
         label: "collaborators",
         icon: <i className="bi bi-person-check"></i>,
         action: () => navigate("/branch/panel/collaborators")
      },
      
      // {
      //    name:"Area Do licenciado",
      //    label: "areadolicenciado",
      //    icon: <i className="bi bi-textarea-resize"></i>,
      //    action: (rota) => navigate("/branch/panel/areadolicenciado")
      // },
      {
         name: "Logout",
         label: "Logout",
         icon: <i className="bi bi-box-arrow-right"></i>,
         action: () => logOut()
      }
   ]

   const routes = user.profile_type === "admin" ? menuItemAdm : menuItemBra

   return (
      <div className="flex h-screen">
         <div style={{ width: isOpen ? "260px" : "50px" }} className="sidebar bg-default-blue">
            <div className="top_section">
               <img
                  src={require("../../assets/images/malug.png")}
                  height="50"
                  width="140"
                  style={{ display: isOpen ? "block" : "none" }}
                  alt='img'
                  className='cursor-pointer'
               />
               <div style={{ marginLeft: isOpen ? "0px" : "0px" }} className="bars">
                  {isOpen ? <i className="bi bi bi-caret-left-fill px-2 rounded-md cursor-pointer" onClick={toggle}></i>
                     : <i className="bi bi bi-caret-right-fill px-2 rounded-md cursor-pointer" onClick={toggle}></i>
                  }
               </div>
            </div>
            {
               routes.map((item, index) => (
                  <div className="p-1.5 m-2 text-[14px] rounded-2xl 
                     flex items-center
                     pl-2.5 duration-300 cursor-pointer 
                     hover:bg-[#F24C3D] text-white"
                     style={{ backgroundColor: item.label === screen ? '#F24C3D' : null }}
                     key={index}
                     onClick={() => item.action(item.label)}
                  >
                     {item.icon}
                     <div style={{ display: isOpen ? "block" : "none" }}>
                        <p className="ml-2 text-white font-bold">{item.name}</p>
                     </div>
                  </div>
               ))
            }
         </div>
         <main>{children}</main>
      </div>
   );
};

export default Sidebar;

