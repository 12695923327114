/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, useParams } from "react-router-dom";

// Estilização Grid Propostas
import '../../components/components.css'

import PopUpHeader from '../../components/popUpHeader'
import PaginatedItems from "../../../../../components/pagination"
import Sucess from '../../components/sucess';
import Exclude from '../../components/exclude';

import FormBranch from './components/formBranch'

import { findState, setCity, findFreight } from '../../../../../utils'

import { HelpersBranchs, HelpersAdress, HelpersFreight } from '../../../../../helpers/index'
const helpersBranchs = new HelpersBranchs()
const helpersAdress = new HelpersAdress()
const helpersFreight = new HelpersFreight()

function Branchs({ states }) {
   const [open, setOpen] = useState(false);
   const [branchs, setBranchs] = useState([]);
   const [branch, setBranch] = useState({});
   const [step, setStep] = useState('edit');
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false)
   const [leadLoading, setLeadLoading] = useState(false)
   const [records, setRecords] = useState(0)
   const navigate = useNavigate();
   const { id } = useParams();

   useEffect(() => {
      if (id === undefined) {
         setOpen(false);
         preListBranchs()
         setBranch({})
         setStep('step0')
         setLeadLoading(false)
      } else {
         setStep('edit')
         setOpen(true);
      }
   }, [id])

   const preListBranchs = (page) => {
      setLoading(true)
      helpersFreight.LitsFreight().then(resp => {
         listBranchs(resp.data.data, page)
      })
   }

   const findCity = async (state_id, city_id) => {
      const city = await helpersAdress.ListCities(state_id).then(resp => {
         const nome = setCity(resp.data.data, city_id)
         return nome
      })
      return city
   }

   const listBranchs = async (freight, page = 0) => {
      helpersBranchs.LitsBranchsPerPage(page).then((resp) => {
         setRecords(resp.data.total)
         Promise.all(resp.data.data.map(async (item) =>
         ({
            ...item,
            state_name: findState(states, item.state_id),
            city_name: await findCity(item.state_id, item.city_id),
            delivery: findFreight(freight, item.city_id)
         })
         )).then((newProductList) => setBranchs(newProductList));
         setLoading(false);
      })
   }

   const updateBranch = (values, freightPrice) => {
      setSaveLoading(true)
      try {
         helpersBranchs.EditBranch(branch.id, values)
         helpersFreight.EditFreight(branch.delivery.id, { city_id: values.city_id, price: freightPrice })
         setSaveLoading(false)
         setStep('sucess')
      } catch (error) {
         console.log(error)
      }

   }

   const saveBranch = async (values, freightPrice) => {
      setSaveLoading(true)
      try {
         await helpersBranchs.SaveBranch(values)
         await helpersFreight.SaveFreight({ city_id: values.city_id, price: freightPrice })
         setSaveLoading(false)
         setStep('sucess')
      } catch (error) {
         console.log(error)
      }
   }

   const deleteBranch = () => {
      try {
         helpersBranchs.DeleteBranch(branch.id)
         setOpen(false);
         preListBranchs()
         setBranch({})
         setStep('step0')
      } catch (error) {
         console.log(error)
      }
   }

   const ModalAdd = () => {
      if (!open) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
            if (id === undefined) {
               setOpen(false);
               setBranch({})
               setStep('step0')
               setLeadLoading(false)
            }
            navigate('/admin/panel/branchs')
         }
      }

      const renderScreen = () => {
         switch (step) {
            case 'edit':
               return <FormBranch
                  header={<PopUpHeader title={'Editar Unidade'} subTitle={'Preencha os dados da unidade'} />}
                  editSaveBranch={(values, freightPrice) => updateBranch(values, freightPrice)}
                  saveLoading={saveLoading}
                  bag={branch}
                  states={states}
                  saveEdit={'edit'}
               />

            case 'step0':
               return <FormBranch
                  header={<PopUpHeader title={'Cadastrar Unidade'} subTitle={'Preencha os dados da unidade'} />}
                  editSaveBranch={(values, freightPrice) => saveBranch(values, freightPrice)}
                  saveLoading={saveLoading}
                  bag={branch}
                  states={states}
                  saveEdit={'save'}
               />

            case 'sucess':
               return <Sucess
                  close={() => {
                     if (id === undefined) {
                        setOpen(false);
                        preListBranchs()
                        setBranch({})
                        setStep('step0')
                        setLeadLoading(false)
                     }
                     navigate('/admin/panel/branchs')
                  }}
               > Unidade criada/atualizada com sucesso </Sucess>

            case 'excluir':
               return <Exclude exclude={() => deleteBranch()}>
                  Tem certeza que deseja excluir {branch.name}?
               </Exclude>

            default:
               break;
         }
      }

      return (
         <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button
                  className="text-white text-xl place-self-end bg-transparent border-0"
                  onClick={() => setOpen(false)}>
                  X
               </button>
               <div className="bg-white p-2 rounded">
                  {
                     leadLoading ?
                        <form className='w-[300px] lg:w-[600px] xl:w-[1000px]' >
                           <section className="flex flex-row">
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                              </div>
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={35} />
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </form>
                        : renderScreen()
                  }
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
         </tr>
      )
   }

   return (
      <div className='px-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Unidades</h2>
            <a
               href="#"
               onClick={() => setOpen(true)}
               className="h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-orange text-white
               rounded-2xl flex justify-center items-center px-5"
            >
               Adicionar licenciado
            </a>
         </div>
         <div className='rounded-lg shadow h-[80%]'>
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ESTADO</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>CIDADE</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ENTREGA/COLETA</th>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EXCLUIR</th>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EDITAR</th>
                  </tr>
               </thead>

               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ?
                        (rows.map((item) => {
                           return (item)
                        })) : (branchs.map((item, index) => {
                           return (
                              <tr className='bg-white' key={index}>
                                 <td className="p-3 text-sm font-semibold text-blue-500 whitespace-nowrap">
                                    <button onClick={() => { }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                       {item.id}
                                    </button>
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                    {item.name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {item.state_name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {item.city_name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    R$ {item.delivery.delivery_price}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <button onClick={() => {
                                       setBranch(item);
                                       setStep('excluir')
                                       setOpen(true)
                                    }}
                                       className="font-bold text-red-500 hover:underline"
                                    >
                                       Excluir
                                    </button>
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <button onClick={() => {
                                       setBranch(item);
                                       navigate(`/admin/panel/branchs/${item.id}`)
                                    }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                       Editar
                                    </button>
                                 </td>
                              </tr>
                           )
                        })
                        )
                  }
               </tbody>
            </table>
         </div>
         <div className='flex flex-row justify-end w-full h-[100px] items-center '>
            <PaginatedItems itemsPerPage={15} setPg={(page) => preListBranchs(page)} qtdItems={records} />
         </div>
         <ModalAdd />
      </div>
   )
}

export default Branchs