import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from "react-router-dom";
import PopUpHeader from '../../components/popUpHeader'
import Sucess from '../../components/sucess';
import moment from 'moment';
import PaginatedItems from "../../../../../components/pagination"

//forms
import AddFormBags from './components/AddFormBags'
import EditFormBags from './components/EditFormBags'
import Exclude from '../../components/exclude';

import { statusBag } from '../../../../../components/renderStatusBag'
import { colorBag } from '../../../../../components/renderColorBag'

import { HelpersBags } from '../../../../../helpers/index'
const helpersBags = new HelpersBags()

const models = [
   {
      image: require("../../../../../assets/images/bag_1.png"),
      value: 3
   },
   {
      image: require("../../../../../assets/images/bag_2.png"),
      value: 2
   },
   {
      image: require("../../../../../assets/images/bag_3.png"),
      value: 1
   },
];

function Estoque({ branchs, branch, setBranch }) {
   const [bags, setBags] = useState([]);
   const [bag, setBag] = useState({});
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false)
   const [records, setRecords] = useState(0);
   const [open, setOpen] = useState(false);
   const [step, setStep] = useState('edit');
   const [error, setError] = useState(false);  

   const navigate = useNavigate();
   const { id } = useParams();

   useEffect(() => {
      const listBags = (page = 1) => {
         helpersBags.EstoqueBags(branch, page).then(resp => {
            setRecords(resp.data.total)
            setBags(resp.data.data)
            setLoading(false)
         })
      }

      listBags()
   }, [ branch ])

   const listBags = (page = 1) => {
      setLoading(true)
      helpersBags.EstoqueBags(branch, page).then(resp => {
         setRecords(resp.data.total)
         setBags(resp.data.data)
         setLoading(false)
      })
   }

   const addBag = (values) => {
      setSaveLoading(true)
      helpersBags.AddBag(values).then(() => {
         setSaveLoading(false)
         setStep('sucess')
      }).catch(error => {   
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      }) 
   }

   const editBag = (values) => {
      setSaveLoading(true)
      helpersBags.EditBag(values, id).then(() => {
         setSaveLoading(false)
         setStep('sucess')
      }).catch(error => {   
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      }) 
   }

   const deleteBag = () => {
      setSaveLoading(true)
      helpersBags.DeleteBag(bag.id).then(() => {
         setSaveLoading(false)
         setStep('sucess')    
      }).catch(error => {   
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      }) 
   }

   const ModalAdd = () => {
      if (!open) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
            if(step === 'sucess') listBags()
            setBag({})
            setOpen(false);
            navigate('/admin/panel/estoque')
         }
      }

      const renderScreen = () => {
         const steps = {
            'edit': <EditFormBags
               header={<PopUpHeader title={'Editar mala'} subTitle={'Preencha os dados da mala'} />}
               editSaveBag={(values) => editBag(values)}
               saveLoading={saveLoading}
               branch={branch}
               error={error}
               bag={bag}
            />,
            'add': <AddFormBags
               header={<PopUpHeader title={'Cadastrar mala'} subTitle={'Preencha os dados da malas'} />}
               editSaveBag={(values) => addBag(values)}
               saveLoading={saveLoading}
               branch={branch}
               error={error}
            />,
            'sucess': <Sucess close={() => {
               listBags()
               setOpen(false);
               navigate('/admin/panel/estoque')
            }}
            >
               Mala criado/atualizada com sucesso
            </Sucess>,
            'excluir': <Exclude  exclude={() => deleteBag()}>
               Tem certeza que deseja excluir a mala {bag.brand} - {bag.model}?
            </Exclude>
         }
         return steps[step]
      }


      return (
         <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0"
                  onClick={() => {
                     if(step === 'sucess') listBags()
                     setBag({})
                     setOpen(false);
                     navigate('/admin/panel/estoque')
                  }}>
                  X
               </button>
               <div className="bg-white p-2 rounded">
                  {renderScreen()}
               </div>
            </div>
         </div>
      )
   }


   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
         </tr>
      )
   }

   return (
      <div className='px-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Estoque</h2>
            <button
               onClick={() => {
                  setOpen(true);
                  setStep('add')
               }}
               className="h-10 text-xs font-bold uppercase tracking-normal bg-default-orange text-white rounded-2xl flex justify-center items-center px-5"
            >
               Adicionar mala
            </button>
         </div>
         <div className="flex flex-col mb-5">
            <label className="text-xs font-medium uppercase mb-1">Selecione a unidade:</label>
               <select 
                  name="color" 
                  className="border border-gray-300 
                  text-sm rounded outline-none
                  focus:border-black  w-full p-2"
                  autoComplete="nope" 
                  required
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)} 
               >  
                  <option value="">TODAS AS UNIDADES</option>
                  {
                     branchs.map((item, index) => {
                        return(
                           <option value={item.id} key={index.toString()} >{item.name} - {item.state_name}</option>
                        )
                     })
                  }
               </select>
         </div>
         <div className="rounded-lg shadow overflow-auto">
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>SKU</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME DA UNIDADE</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>TAMANHO</th>
                     <th className='w-2/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>MARCA/MODELO</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>DATA DE CRIAÇÃO</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>COR</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>STATUS</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EXCLUIR</th>
                     <th className='w-1/8 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EDITAR</th>
                  </tr>
               </thead>

               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {  
                     loading ? (rows.map((item) => {
                        return (item)
                     })) : (bags.map((item, index) => {
                        return (
                           <tr className='bg-white overflow-auto' key={index.toString()}>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                 <p className="font-bold text-blue-500 hover:underline">
                                    {item.identification}
                                 </p>
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold ">
                                 {item.branch_name}
                              </td>
                              <td className="flex items-center p-3 text-sm font-semibold text-gray-700 whitespace-nowrap">
                                 <img 
                                    src={models.find(el => el.value === item.bag_size_id).image} 
                                    width="25" 
                                    height="25"
                                    alt='img'
                                 /> &nbsp; {item.bag_size_name}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                                 {item.brand} - {item.model}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                                 {moment(item.buy_at).format('ll')}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 {colorBag(item.color)}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 {statusBag(item.status)}
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 <button 
                                    onClick={() => { 
                                       setBag(item)
                                       setStep('excluir')
                                       setOpen(true)}
                                    }
                                    className="font-bold text-red-500 hover:underline"
                                 >
                                    Excluir
                                 </button>
                              </td>
                              <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                 <button onClick={() => { 
                                    setBag(item)
                                    setStep('edit')
                                    setOpen(true)
                                    navigate(`/admin/panel/estoque/${item.id}`)}
                                 }
                                    className="font-bold text-blue-500 hover:underline"
                                 >
                                    Editar
                                 </button> 
                              </td>
                           </tr>
                        )
                     }))
                  }
               </tbody>
            </table>
         </div>
         <div className='flex flex-row justify-between w-full h-[100px] items-center '>
            <p className='text-slate-400 text-sm italic'>Resultados: {records}</p>
            <PaginatedItems itemsPerPage={15} setPg={(page) => listBags(page)} qtdItems={records}/>
         </div>
         <ModalAdd />
      </div>

   )
}

export default Estoque