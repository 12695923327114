import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}


class HelpersBranchs {
    async LitsBranchs() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('admin/branch/list', {
					headers: {
					   'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async LitsBranchsPerPage(page) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('admin/branch/list?page='+page, {
					headers: {
					   'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async EditBranch(id, values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.put('/admin/branch/'+id, values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async SaveBranch(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.post('/admin/branch', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async DeleteBranch(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.delete('/admin/branch/'+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}


}

export { HelpersBranchs };