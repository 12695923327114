import React, { useState, useEffect } from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';

import { HelpersCupons } from '../../../../../../helpers/index'
const helpersCupons = new HelpersCupons()


export default function AddFormCupom({id}) {
   const [cupomName, setCupomName] = useState("");
   const [type, setType] = useState("")
   const [value, setValue] = useState("");
   const [expiresDate, setExpiresDate] = useState("");
   const [loading, setLoading] = useState(false)
   const [loadingSave, setLoadingSave] = useState(false)
   const [cupons, setCupons] = useState([])

   useEffect(() => {
      const listCoupons = () => {
         helpersCupons.ListCupons(id).then(resp  => {
            setCupons(resp.data.data)
         })
      }

      listCoupons()
   }, [])

   const listCoupons = () => {
      helpersCupons.ListCupons(id).then(resp  => {
         setCupons(resp.data.data)
      })
   }

   const addCupom = (e) => {
      e.preventDefault();

      var cupom = {
         discount_type: type, //fixed ou percentage
         discount: value,
         influencer_id: id,
         description: "-",
         expires_at: moment(expiresDate).format('DD/MM/YYYY h:mm') ,
         code: cupomName // Se não informado, gera um automatico
      }

      helpersCupons.CreateCupons(cupom).then(resp  => {
         listCoupons()
         setType('')
         setValue('')
         setExpiresDate('')
         setCupomName('')
      })
   }

   return (
      <>
         <form className="flex flex-row gap-3 my-5" onSubmit={addCupom}>
            <div className="form-group">
               <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium uppercase mb-1">Nome:</label>
                  <input
                     type="text"
                     name="Marca"
                     className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                     autoComplete="nope"
                     placeholder='Nome do cupom'
                     maxLength="100"
                     required
                     value={cupomName}
                     onChange={(e) => setCupomName(e.target.value)}
                  />
               </div>
            </div>
            <div className="form-group">
               <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium uppercase mb-1">Tipo:</label>
                  <select  
                     name="nascimento" 
                     className="border border-gray-300 
                     text-sm rounded outline-none
                     focus:border-black  w-full p-2.5"
                     autoComplete="nope" 
                     required
                     value={type}
                     onChange={(e) => setType(e.target.value)} 
                  >
                     <option value="">Tipo de desconto</option>
                     <option value="percentage">Percentual</option>
                     <option value="fixed">Fixo</option>
                  </select>
               </div>
            </div>
            <div className="form-group">
               <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium uppercase mb-1">Valor:</label>
                  <input
                     type="text"
                     name="purchaseDate"
                     className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                     autoComplete="nope"
                     placeholder='Porecentagem de desconto'
                     maxLength="100"
                     required
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                  />
               </div>
            </div>
            <div className="form-group">
               <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium uppercase mb-1">Expiração:</label>
                  <input 
                     type="date"
                     name="nascimento"
                     className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                     autoComplete="nope" required
                     maxLength={10}
                     value={expiresDate}
                     onChange={(e) => setExpiresDate(e.target.value)}
                  />
               </div>
            </div>
            <button className="flex items-center m-3 rounded-lg" type='submit' disabled={loadingSave}>
               +
            </button>
         </form>
         <table className='w-full'>
            <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
               <tr>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>CUPOM</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>TIPO</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>VALOR</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EXPIRAÇÃO</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>RESERVAS</th>
                  <th className='w-1/7 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>GANHOS</th>
               </tr>
            </thead>
            <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
               {
                  cupons.map((item, index) => {
                     return (
                        <tr className='bg-white overflow-auto' key={index.toString()}>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                              <p className="font-bold text-blue-500 hover:underline">
                                 {item.id}
                              </p>
                           </td>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-medium ">
                              {item.code}
                           </td>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-medium ">
                              {item.discount_type === "fixed" ? 'R$' : null }
                              {item.discount_type === "percentage" ? '%' : null }
                           </td>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-medium ">
                              {item.discount}
                           </td>
                           <td className="flex items-center p-3 text-sm font-medium text-gray-700 whitespace-nowrap">
                              {moment(item.expires_at).format('DD/MM/YYYY')}
                           </td>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                              0
                           </td>
                           <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                              0
                           </td>
                        </tr>
                     )
                  })
               }
            </tbody>
         </table>
      </>
   )
}