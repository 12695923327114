import React from 'react'

function sucess({children, close}) {
  return (
    <div className='w-[400px] h-[280px] rounded'>
        <div className='flex h-1/3 justify-center items-center'>
            <i className="bi bi-check-circle" style={{fontSize: 80, color: 'green'}}></i>
        </div>
        <div className='flex h-2/3 flex-col justify-evenly items-center'>
            <h1 className="font-bold text-2xl">Concluído</h1>
            <h1 className='text-center font-semibold text-xl'>{children}</h1>
            <button
                href="#"
                onClick={() => close()}
                className="w-24 h-10 text-xs font-bold uppercase 
                tracking-wider bg-default-blue text-white
                rounded flex justify-center items-center"
            >
                OK
            </button>
        </div>
    </div>
  )
}

export default sucess