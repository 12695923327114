/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from "react-router-dom";

// Estilização Grid Propostas
import '../../components/components.css'

import PopUpHeader from '../../components/popUpHeader'

import Sucess from '../../components/sucess';
import Exclude from '../../components/exclude';

import FormColaborators from './components/formColaborators'


import { HelpersCollaborators, HelpersAuth } from '../../../../../helpers/index'

const helpersCollaborators = new HelpersCollaborators()
const helpersAuth = new HelpersAuth()


function Branchs({user}) {
   const [open, setOpen] = useState(false);
   const [colaborators, setColaborators] = useState([])
   const [colaborator, setColaborator] = useState({})
   const [step, setStep] = useState('excluir');
   const [loading, setLoading] = useState(false);
   const [saveLoading, setSaveLoading] = useState(false)
   const [error, setError] = useState('')
   const navigate = useNavigate();
   
   useEffect(() => {
      const preListColaborator = () => { 
         setLoading(true)
         helpersCollaborators.LitsCollaboratorsBranch(user.branch_id).then(resp => {
            setColaborators(resp.data.data)
            setLoading(false)
         })
      }

      preListColaborator()
   }, [])   
   
   const preListColaborator = () => { 
      setLoading(true)
      helpersCollaborators.LitsCollaboratorsBranch(user.branch_id).then(resp => {
         setColaborators(resp.data.data)
         setLoading(false)
      })
   }
   
   const updateCollaborator = (values, freightPrice, deliveryId) => {
      setSaveLoading(true)
      try {
        
      } catch (error) {
         setSaveLoading(true)
         console.log(error)
      }
   }

   const createCollaborator = (values) => {
      setSaveLoading(true)
      
      helpersAuth.CreateUserBranch(values).then(resp => {
         setSaveLoading(false)
         setStep('sucess')
      }).catch((error) =>  {
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      })
         
   }

   const deleteCollaborator = () => {
      try {
         helpersCollaborators.DeleteCollaboratorBranch(colaborator.id)
         setOpen(false)
         setStep('edit')
         preListColaborator()
         setColaborator({});
      } catch (error) {
         setSaveLoading(false)
         console.log(error)
      }
   }

   const ModalAdd = () => {
      if(!open) return null;

      const handleClose = (e) => {
         if(e.target.id === 'wrapper') {
            if(step === 'sucess') preListColaborator()
            setOpen(false);
            navigate('/branch/panel/collaborators')
         }
      } 

      const renderScreen = () => {
         switch (step) {
            case 'edit':
               return <FormColaborators
                  header={<PopUpHeader title={'Editar Colaborador'} subTitle={'Preencha os dados do colaborador da unidade'}/>} 
                  editSaveColaborator={() => updateCollaborator()}
                  saveLoading={saveLoading}
                  bag={user.branch_id}
                  colaborator={colaborator}
                  error={error}
               />

            case 'add':
               return  <FormColaborators
                  header={<PopUpHeader title={'Cadastrar colaborador'} subTitle={'Preencha os dados do colaborador da unidade'}/>} 
                  editSaveColaborator={(values) => createCollaborator(values)}
                  saveLoading={saveLoading}
                  bag={user.branch_id}
                  colaborator={colaborator}
                  error={error}
               />
               
            case 'sucess':
               return  <Sucess 
                  close={() => { 
                     preListColaborator()
                     setOpen(false);
                     navigate('/branch/panel/collaborators')}}
               >Colaborador criado com sucesso</Sucess>

            case 'excluir':
               return  <Exclude  exclude={() => deleteCollaborator()}>
                  Tem certeza que deseja excluir {colaborator.name}?
               </Exclude>
   
            default:
               break;
         }
      }

      return (
         <div 
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0" 
                  onClick={() => {
                     if(step === 'sucess')preListColaborator()
                     setOpen(false);
                     navigate('/branch/panel/collaborators')
                  }}>
                     X
               </button>
               <div className="bg-white p-2 rounded">
                  {renderScreen()}
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
         </tr>
      )
   }

   return (
      <div className='px-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Administradores</h2>
            <a 
               href="#"
               onClick={() => {
                  setOpen(true)
                  setStep("add")
               }}
               className="w-48 h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-blue text-white
               rounded-2xl flex justify-center items-center"
            >
               Adicionar Colaborador
            </a>
         </div>
         <div className='overflow-y-scroll rounded-lg shadow h-[80%]'>
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EMAIL</th>
                  </tr>
               </thead>
               
               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ? 
                        (rows.map((item) => {
                           return (item)
                        })) : (colaborators.map((item, index) => {
                           return(
                              <tr className='bg-white' key={index}>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                    {item.id}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                    {item.name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {item.email}
                                 </td>
                                 {/*<td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <button onClick={() => {
                                       setColaborator(item);
                                       navigate(`/branch/panel/collaborators/${item.id}`)
                                    }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                       Editar
                                    </button>
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {/* {<button onClick={() => {
                                       setColaborator(item);
                                       setStep('excluir')
                                       setOpen(true)
                                    }}
                                       className="font-bold text-default-red hover:underline"
                                    >
                                       Excluir
                                    </button>} 
                                 </td>*/}
                              </tr> 
                           )
                        })
                     ) 
                  }
               </tbody>
            </table>
         </div>
         <ModalAdd />
      </div>   
   )
}

export default Branchs