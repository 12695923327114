import React, { useState } from 'react'
import { valid, formataCPF } from '../../../../../../utils' 


function Step1({header, editSaveColaborator, saveLoading, bag, colaborator, error, readOnly}) {
   const [id, setID] = useState((valid(colaborator.id) ? colaborator.id : '' ));
   const [cpf, setCPF] = useState((valid(colaborator.cpf) ? colaborator.cpf : '' ));
   const [name, setName] = useState((valid(colaborator.name) ? colaborator.name : '' ));
   const [email, setEmail] = useState((valid(colaborator.email) ? colaborator.email : '' ))
   const [password, setPassword] = useState("")
   const [confirmPassword, setConfirmPassword] = useState("")

   const next = (e) => {
      e.preventDefault();
      let data = {
         id: id,
         user_profile:"branch",
         name : name,
         email: email,
         cpf: cpf,
         branch_id: bag,
         password: password,
         password_confirmation: confirmPassword,
      }
      editSaveColaborator(data)
   }

   return (
      <form  autoComplete="off" className='w-[300px] lg:w-[600px] xl:w-[1000px]' onSubmit={next}>
         {header}
         <section className="flex flex-row">
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                    <label className="text-xs font-medium uppercase mb-1">ID <span className="text-red-600">*</span></label>
                    <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Id do colaborador'
                        maxLength="100" 
                        required 
                        readOnly
                        minLength={6}
                        value={id}
                        onChange={(e) => setID(e.target.value)}
                    />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Nome do colaborador<span className="text-red-600">*</span></label>
                     <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Digite o nome do colaborador'
                        maxLength="100" 
                        autoFocus 
                        required 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                    <label className="text-xs font-medium uppercase mb-1">Senha <span className="text-red-600">*</span></label>
                    <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Digite a senha'
                        maxLength="100" 
                        required 
                        minLength={6}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
               </div>
            </div>
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                    <label className="text-xs font-medium uppercase mb-1">Email <span className="text-red-600">*</span></label>
                    <input type="email" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Nome o email do colaborador'
                        maxLength="100" 
                        required 
                        readOnly={readOnly}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">CPF <span className="text-red-600">*</span></label>
                     <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='CPF'
                        maxLength={14} 
                        required 
                        minLength={6}
                        value={formataCPF(cpf)}
                        onChange={(e) => setCPF(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Confirmação de senha <span className="text-red-600">*</span></label>
                     <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Digite a confirmação de senha'
                        maxLength="100" 
                        required 
                        minLength={6}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                     />
                  </div>
               </div>
            </div>
         </section>
         {
            error ? <div className='bg-red-300 rounded-lg p-2 mx-3'>
               <p className="text-red-600">
                  {error  ===
                     'The email has already been taken.' ?
                     'Este email já está sendo usado, tente outro.' : null
                  }
                  {error  ===
                     'The email has already been taken. (and 1 more error)' ?
                     'Este email já está sendo usado, tente outro.' : null
                  }
                  {error  ===
                     'The password confirmation does not match.' ?
                     'As senhas não se correspondem.' : null
                  }
               </p>
            </div> : null
         }
         <section className="flex justify-end p-3">
            <button 
               type='submit'
               className="w-24 h-10 text-xs font-bold uppercase 
                  tracking-wider bg-default-blue text-white
                  rounded flex justify-center items-center"
                  disabled={saveLoading}
               >
                  {saveLoading ? 'Salvando...' : 'Salvar'}
            </button>
         </section>
      </form>
   )
}

export default Step1