import api from './Api'


class HelpersAdress {
    async LitStates() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const states = await api.get('address/states', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',

					},
				})
			   resolve (states)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async ListCities(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const cities = await api.get('address/cities?state_id='+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',

					},
				})
			   resolve (cities)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

}

export { HelpersAdress };