import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}


class HelpersInfluencers {
   
   async ListInfluencer() {
      return new Promise(async (resolve, reject) => {
         try {
            const branch = await api.get('/admin/influencer/list', {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
               },
            })

            resolve(branch)
         } catch (error) {
            reject(error)
         }
      })
   }

   async CreateInfluencer(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.post('/admin/influencer/create', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}
}

export { HelpersInfluencers };