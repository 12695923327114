import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Panel from './screens/Panel';
import Login from './screens/Login'

import { connect } from "react-redux";

import { setStates } from './redux/actions'

const PrivateRoute = ({ children, redirectTo }) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

//247Xg#xN7^1n

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
         <Route
            path="/admin/panel/dashboard"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"dashboard"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/bags"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"bags"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/bags/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"bags"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/admins"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"admins"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/admins/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"admins"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/branchs"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"branchs"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/branchs/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"branchs"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/collaborators"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"collaborators"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/estoque"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"estoque"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/estoque/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"estoque"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/collaborators/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"collaborators"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/reservas"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"reservas"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/reservas/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"reservas"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/manualdolicenciado"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"manualdolicenciado"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/admin/panel/influencers"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"influencers"}/>
               </PrivateRoute>
            }
         />

  {/*-------------------- Rotas de filias ----------------------- */}

         <Route
            path="/branch/panel/dashboard"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"dashboard"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/branch/panel/estoque"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"estoque"}/>
               </PrivateRoute>
            }
         />
         {/* <Route
            path="/branch/panel/areadolicenciado"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"areadolicenciado"}/>
               </PrivateRoute>
            }
         /> */}
         <Route
            path="/branch/panel/manualdolicenciado"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"manualdolicenciado"}/>
               </PrivateRoute>
            }
         />
         {/* <Route
            path="/branch/panel/notificacoes"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"notificationss"}/>
               </PrivateRoute>
            }
         /> */}
         <Route
            path="/branch/panel/collaborators"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"collaborators"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/branch/panel/collaborators/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"collaborators"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/branch/panel/reservas"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"reservas"}/>
               </PrivateRoute>
            }
         />
         <Route
            path="/branch/panel/reservas/:id"
            element={
               <PrivateRoute redirectTo="/">
               <Panel rota={"reservas"}/>
               </PrivateRoute>
            }
         />

        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>  
  );
}

const mapStateToProps = (state) => {
	const { loading, error } = state.Auth;
	return { loading, error };
 };
 
export default connect(mapStateToProps, { setStates })(App);


