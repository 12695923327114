import React from 'react'

function PopUpHeader({title, subTitle}) {
   return(
      <>               
         <div className="flex flex-row justify-between items-center p-3">
            <div>
               <h1 className="font-bold text-2xl">{title}</h1>
               <p>{subTitle}</p>
            </div>
         </div>
      </>
   )
}

export default PopUpHeader
