import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}

class HelpersFreight {
    async LitsFreight() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('/admin/delivery_setting/list', {
					headers: {
					   'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async EditFreight(id, values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.put('/admin/delivery_setting/'+id, values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async SaveFreight(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.post('/admin/delivery_setting', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}
}

export { HelpersFreight };