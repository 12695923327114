import React, { useEffect, useState } from 'react'

import { HelpersDashboard } from '../../../../../helpers'
const helpersDashboard = new HelpersDashboard()

function Dashboard() {
   const [dashboard, setDashboard] = useState({})

   useEffect(() => {
      const dashboard = () => {
         helpersDashboard.listDashboard().then(resp  => {
            setDashboard(resp.data.data)
         })
      }

      dashboard()
   }, [])
   

   return (
      <div className='h-screen px-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Dashboard</h2>
         </div>
         <div className='flex  gap-3'>
            <div className='h-40 flex-1 bg-red-500 flex justify-around items-center'>
               <div className='h-full w-5 bg-red-700'></div>
               <div className='flex flex-col justify-center'>
                  <p className='text-xl font-bold text-white'>Admins</p>
                  <p className='text-xl uppercase font-bold text-white'>{dashboard?.users?.total_admin}</p>
               </div>
               <i className="bi bi-person-workspace text-5xl"></i>
            </div>
            <div className='h-40 flex-1 bg-blue-500 flex justify-around items-center'>
               <div className='h-full w-5 bg-blue-700'></div>
               <div className='flex flex-col justify-center'>
                  <p className='text-xl font-bold text-white'>Filiais</p>
                  <p className='text-xl uppercase font-bold text-white'>{dashboard?.branches?.total}</p>
               </div>
               <i className="bi bi-house text-5xl"></i>
            </div>
            <div className='h-40 flex-1 bg-yellow-500 flex justify-around items-center'>
               <div className='h-full w-5 bg-yellow-700'></div>
               <div className='flex flex-col justify-center'>
                  <p className='text-xl font-bold text-white'>Reservas</p>
                  <p className='text-xl uppercase font-bold text-white'>{dashboard?.bookings?.total}</p>
               </div>
               <i className="bi bi-file-earmark-text text-5xl"></i>
            </div>
            <div className='h-40 flex-1 bg-green-500 flex justify-around items-center'>
               <div className='h-full w-5 bg-green-700'></div>
               <div className='flex flex-col justify-center'>
                  <p className='text-xl font-bold text-white'>Usuários</p>
                  <p className='text-xl uppercase font-bold text-white'>{dashboard?.users?.total_user}</p>
               </div>
               <i className="bi bi-file-person text-5xl"></i>
            </div>
         </div>
      </div>
   )
}

export default Dashboard


