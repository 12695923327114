import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}

class HelpersDashboard {
    async listDashboard() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('/admin/dashboard/global', {
					headers: {
					   'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		    } catch (error) {
			  reject (error)
		    }
		})
	}
	
}

export { HelpersDashboard };