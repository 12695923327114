import React, { useState, useEffect } from 'react'
import { valid, banks } from '../../../../../../utils' 

import { HelpersAdress } from '../../../../../../helpers/index'
const helpersAdress = new HelpersAdress()

function Step1({header, editSaveBranch, saveLoading, bag, states, saveEdit}) {
   const [name, setName] = useState((valid(bag.name) ? bag.name : '' ));
   const [cityBranch, setCityBranch] = useState((valid(bag.city_id) ? bag.city_id : 0 ));
   const [cityBranchs, setCityBranchs] = useState([]);
   const [stateBranch, setStateBranch] = useState((valid(bag.state_id) ? bag.state_id : 0 ));
   const [freightPrice, setfreightPrice] = useState((valid(bag.delivery?.delivery_price) ? bag.delivery?.delivery_price : '' ));
   const [recipientName, setRecipientName] = useState('')
   const [titular, setTitular] = useState('')
   const [agencyNumber, setAgencyNumber] = useState('')
   const [agencyDigit, setAgencyDigit] = useState('')
   const [accountType, setAccountType] = useState('')
   const [holderType, setHoldertype] = useState('')
   const [document, setDocument] = useState('')
   const [bank, setBank] = useState('')
   const [accountNumber, setAccountNumber] = useState('')
   const [accountDigit, setAccountDigit] = useState('')


   useEffect(() => {
      findCity(stateBranch)
   }, [stateBranch])
   

   const findCity = async(sta) => {
      await helpersAdress.ListCities(sta).then(resp => {
         setCityBranchs(resp.data.data)
      })
   } 

   const next = (e) => {
      e.preventDefault();
      let data = {
         name: name,
         city_id: cityBranch,
         state_id: stateBranch,
         payment_details: {
            name:  recipientName,
            document: document,
            type: holderType,
            bank_account: {
               holder_name: titular,
               bank: bank,
               branch_number: agencyNumber,
               branch_check_digit: agencyDigit === "" ? "0" : agencyDigit,
               account_number: accountNumber,
               account_check_digit: accountDigit,
               holder_type: holderType,
               holder_document: document,
               type: accountType
            }
         }
      }
      editSaveBranch(data, freightPrice)
   }

   return (
      <form  autoComplete="off" className='w-[300px] lg:w-[600px] xl:w-[1000px]' onSubmit={next}>
         {header}
         <section className="flex flex-row">
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Nome da unidade <span className="text-red-600">*</span></label>
                     <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Nome da unidade'
                        maxLength="100" required 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Cidade <span className="text-red-600">*</span></label>
                        <select  type="text" name="email" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2"
                           autoComplete="nope" maxLength="60" required 
                           value={cityBranch}
                           onChange={(e) => setCityBranch(e.target.value)}
                        >  
                           <option value="">Selecione a cidade</option>
                           {
                              cityBranchs.map((item, index) => 
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              )
                           }
                        </select>
                  </div>
               </div>
            </div>
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Estado <span className="text-red-600">*</span></label>
                     <select  name="nascimento" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope" required
                        maxLength={10}
                        value={stateBranch}
                        onChange={(e) => setStateBranch(e.target.value)} 
                     >
                        <option value="">Selecione o estado</option>
                        {
                           states.map((item, index) => 
                              <option value={item.id} key={index.toString()}>{item.name}</option>
                           )
                        }
                     </select>
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Preço Entrega/coleta <span className="text-red-600">*</span></label>
                     <input type="text" name="nome" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2" 
                        autoComplete="nope" 
                        placeholder='Preço do Entrega/coleta'
                        maxLength="100" 
                        required 
                        value={freightPrice}
                        onChange={(e) => setfreightPrice(e.target.value)}
                     />
                  </div>
               </div>
            </div>
         </section>

         {
            saveEdit === "save" ? <>
            <h3 className='p-3 uppercase font-semibold'>Dados bancários:</h3>
            <section className="flex flex-row">
               <div className="basis-1/2 p-3">
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Nome do recebedor <span className="text-red-600">*</span></label>
                        <input type="text" name="nome" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2" 
                           autoComplete="nope" 
                           placeholder='Nome do recebedor'
                           maxLength="128" required 
                           value={recipientName}
                           onChange={(e) => setRecipientName(e.target.value)}
                        />
                     </div>
                  </div>
   
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Número do Documento <span className="text-red-600">*</span></label>
                        <input type="text" name="nome" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2" 
                           autoComplete="nope" 
                           placeholder='Número do documento (CNPJ ou CPF)'
                           maxLength="16" required 
                           value={document}
                           onChange={(e) => setDocument(e.target.value)}
                        />
                     </div>
                  </div>
   
                  
                  <div className="form-group">
                     <div className="flex flex-row mb-5 flex gap-3">
                        <div className='basis-2/3'>
                           <label className="text-xs font-medium uppercase mb-1">Número da agência <span className="text-red-600">*</span></label>
                           <input type="text" name="nome" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2" 
                              autoComplete="nope" 
                              placeholder='Número da agência'
                              maxLength="4" 
                              required 
                              value={agencyNumber}
                              onChange={(e) => setAgencyNumber(e.target.value)}
                           />
                        </div> 
                        <div className='basis-1/3'>
                           <label className="text-xs font-medium uppercase mb-1">Dígito</label>
                           <input type="text" name="nome" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2" 
                              autoComplete="nope" 
                              placeholder='Digito da agência'
                              maxLength="1"  
                              value={agencyDigit}
                              onChange={(e) => setAgencyDigit(e.target.value)}
                           />
                        </div> 
                     </div>
                  </div>
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Tipo de conta bancária <span className="text-red-600">*</span></label>
                        <select  type="text" name="email" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2.5 bg-[transparent]"
                           autoComplete="nope" maxLength="60" required 
                           value={accountType}
                           onChange={(e) => setAccountType(e.target.value)}
                        >  
                           <option value="">Selecione o banco</option>
                           <option value="checking">Corrente</option>
                           <option value="savings">Poupança</option>
                        </select>
                     </div>
                  </div>
               </div>
               <div className="basis-1/2 p-3">
               <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Tipo de documento <span className="text-red-600">*</span></label>
                        <select  type="text" name="email" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2.5 bg-[transparent]"
                           autoComplete="nope" maxLength="60" required 
                           value={holderType}
                           onChange={(e) => setHoldertype(e.target.value)}
                        >  
                           <option value="">Selecione o tipo de documento</option>
                           <option value="individual">CPF</option>
                           <option value="company">CNPJ</option>
                        </select>
                     </div>
                  </div>
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Titular da conta bancária <span className="text-red-600">*</span></label>
                        <input type="text" name="nome" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2" 
                           autoComplete="nope" 
                           placeholder='Nome completo do  titular da conta'
                           maxLength="30" 
                           required 
                           value={titular}
                           onChange={(e) => setTitular(e.target.value)}
                        />
                     </div>
                  </div>
   
                  <div className="form-group">
                     <div className="form-group">
                        <div className="flex flex-col mb-5">
                              <label className="text-xs font-medium uppercase mb-1">Banco <span className="text-red-600">*</span></label>
                              <select  type="text" name="email" 
                                 className="border border-gray-300 
                                 text-sm rounded outline-none
                                 focus:border-black  w-full p-2.5 bg-[transparent]"
                                 autoComplete="nope" maxLength="3" required 
                                 value={bank}
                                 onChange={(e) => setBank(e.target.value)}
                              >  
                                 <option value="">Selecione o banco</option>
                                 {
                                    banks.map((item, index) => 
                                       <option value={item.codigo} key={index.toString()}>{item.nome}</option>
                                    )
                                 }
                              </select>
                        </div>
                     </div>
                  </div>
                  <div className="form-group">
                     <div className="flex flex-row mb-5 flex gap-3">
                        <div className='basis-2/3'>
                           <label className="text-xs font-medium uppercase mb-1">Número da conta <span className="text-red-600">*</span></label>
                           <input type="text" name="nome" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2" 
                              autoComplete="nope" 
                              placeholder='Número da conta'
                              maxLength="13" 
                              required 
                              value={accountNumber}
                              onChange={(e) => setAccountNumber(e.target.value)}
                           />
                        </div> 
                        <div className='basis-1/3'>
                           <label className="text-xs font-medium uppercase mb-1">Dígito <span className="text-red-600">*</span></label>
                           <input type="text" name="nome" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2" 
                              autoComplete="nope" 
                              placeholder='Digíto da conta'
                              maxLength="2" 
                              required 
                              value={accountDigit}
                              onChange={(e) => setAccountDigit(e.target.value)}
                           />
                        </div> 
                     </div>
                     
                  </div>
               </div>
            </section> </> : null
         }
      
         <section className="flex justify-end p-3">
            <button 
               type='submit'
               className="w-24 h-10 text-xs font-bold uppercase 
                  tracking-wider bg-default-blue text-white
                  rounded flex justify-center items-center"
                  disabled={saveLoading}
               >
                  {saveLoading ? 'Salvando...' : 'Salvar'}
            </button>
         </section>
      </form>
   )
}

export default Step1