/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, useParams } from "react-router-dom";

// Estilização Grid Propostas
import '../../components/components.css'

import PopUpHeader from '../../components/popUpHeader'

import Sucess from '../../components/sucess';

import FormBag from './components/formBag'


import { HelpersBags } from '../../../../../helpers/index'
const helpersBags = new HelpersBags()

function TamMala() {
   const [open, setOpen] = useState(false);
   const [bags, setBags] = useState([]);
   const [bag, setBag] = useState({});
   const [step, setStep] = useState('step0');
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false)
   const [leadLoading, setLeadLoading] = useState(false)
   const navigate = useNavigate();
   const { id } = useParams();

   useEffect(() => {
      if(id === undefined) {
         setOpen(false);
         listBags()
         setBag({}) 
         setStep('step0')
         setLeadLoading(false)
      }else {
         setOpen(true);
      }
   }, [id])

   const listBags = () => {
      setLoading(true)
      helpersBags.ListBagsSizes().then(resp => {
         setBags(resp.data.data);
         setLoading(false);
      })
   }

   const updateBag = (values) => {
      setSaveLoading(true)
      helpersBags.EditBagSize(bag.id, values).then(resp => {
         setSaveLoading(false)
         setStep('sucess')
      })
   }

   const ModalAdd = () => {
      if(!open) return null;

      const handleClose = (e) => {
         if(e.target.id === 'wrapper') {
            if (id === undefined){
               setOpen(false);
            }
            navigate('/admin/panel/bags')
         }
      } 

      const renderScreen = () => {
          
         switch (step) {
            case 'step0':
               return   <FormBag 
                  header={<PopUpHeader title={'Editar mala'} subTitle={'Preencha os dados da mala'}/>} 
                  editBag={(values) => updateBag(values)}
                  saveLoading={saveLoading}
                  bag={bag}
               />
            
            case 'sucess':
               return  <Sucess 
                  close={() => { 
                     navigate('/admin/panel/bags')
                  }}
               > Tamalho de mala atualizado </Sucess>
   
            default:
               break;
         }
      }

      return (
         <div 
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0" 
                  onClick={() => {if (id === undefined){
                     setOpen(false); 
                  }
                     navigate('/admin/panel/bags')
                  }}>
                     X
               </button>
               <div className="bg-white p-2 rounded">
                  {
                     leadLoading ?  
                        <form  className='w-[300px] lg:w-[600px] xl:w-[1000px]' >
                           <section className="flex flex-row">
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                              </div>
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </form>
                     : renderScreen()
                  }
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
         </tr>
      )
   }

   return (
      <div className='pl-5 pr-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Tamanhos de Malas</h2>
            {/*<a 
               href="#"
               onClick={() => setOpen(true)}
               className="w-24 h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-red text-white
               rounded-2xl flex justify-center items-center"
            >
               Adicionar
            </a>*/}
         </div>
         <div className='overflow-y-scroll rounded-lg shadow h-[80%]'>
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>PREÇO</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>PREÇO SEGURO</th>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EDITAR</th>
                  </tr>
               </thead>
               
               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ? 
                        (rows.map((item) => {
                           return (item)
                        })) : (bags.map((item, index) => {
                           return(
                              <tr className='bg-white' key={index}>
                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                    {item.id}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                    {item.name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    R$ {item.price}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    R$ {item.secure_value}                
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <button onClick={() => {
                                       setBag(item);
                                       navigate(`/admin/panel/bags/${item.id}`)
                                    }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                       Editar
                                    </button>
                                 </td>
                              </tr> 
                           )
                        })
                     ) 
                  }
               </tbody>
            </table>
         </div>
         <ModalAdd />
      </div>   
   )
}

export default TamMala