/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";

//actions 
import { loginUser } from '../../redux/actions'

function Login(props) {
   const { loginUser, loading, error } = props
   const [email, setEmail] = useState('')
   const [senha, setSenha] = useState('')

   const navigate = useNavigate();

   useEffect(() => {
      if (!loading && localStorage.getItem("token") !== null) {
         navigate("admin/panel/dashboard")
      }
   }, [loading, navigate]);

   const login = (e) => {
      e.preventDefault();
      loginUser(email, senha)
   }

   return (
      <div className="w-full bg-[url(assets/images/background_login.jpg)] bg-center bg-no-repeat bg-cover h-screen flex items-center justify-center">
         <div className="md:w-[500px] w-[80%] mx-auto flex flex-col">
            <div className="bg-white p-2 rounded ">
               <div className="py-6 px-6 lg:px-8 text-left">
                  <h3 className="mb-1 text-xl font-bold text-gray-900 text-center">
                     ADM MALUG - Aluguel de Malas
                  </h3>
                  <h3 className="mb-4 text-xs font-medium text-gray-900 text-center">
                     Gerenciamento de sistema
                  </h3>
                  <form className="space-y-6" onSubmit={login}>
                     <div>
                        <label 
                        
                           className="block mb-2 text-sm font-semibold text-gray-900"
                        >
                           Seu email:
                        </label>
                        <input
                           type="email"
                           name="email"
                           id="email"
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           className="bg-gray-50 border border-gray-300 
                           text-gray-900 text-sm rounded-lg outline-none
                           focus:border-default-red block w-full p-2.5"
                           placeholder="Exemplo: carlos@gmail.com" 
                           required
                        />    
                     </div>
                     <div>
                        <label 
                           className="block mb-2 text-sm font-semibold text-gray-900"
                        >
                           Sua senha:
                        </label>
                        <input
                           type="password"
                           name="password"
                           id="password"
                           value={senha}
                           onChange={(e) => setSenha(e.target.value)}
                           className="bg-gray-50 border border-gray-300 
                           text-gray-900 text-sm rounded-lg outline-none
                           focus:border-default-red block w-full p-2.5"
                           required
                        />    
                     </div>
                     {/*<div className="flex justify-between">
                        <div className="flex items-start">
                           <div className="flex items-center h-5">
                                 <input
                                    id="remember"
                                    type="checkbox"
                                    value=""
                                    className="w-4 h-4 bg-gray-50 rounded border border-gray-300 
                                    focus:ring-3 focus:ring-blue-300" 
                                 />
                           </div>
                           <label className="ml-2 text-sm font-semibold text-gray-900">
                              Lembrar-me
                           </label>
                        </div>
                        {/*<a href="#" className="text-sm text-default-red hover:underline">
                           Esqueci minha senha
                        </a>
                     </div>*/}
                     {
                        error ? <div className='w-full bg-red-300 rounded-lg py-2 px-3'>
                           <p className="text-red-600">
                              Email/Senha inválido(os)
                           </p>
                        </div> : null
                     }
                     <button 
                        type="submit"
                        className="w-full text-white bg-default-blue hover:bg-[#3e3e3e] 
                        focus:ring-4 focus:outline-none focus:ring-blue-300 
                        font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                     >
                        {loading ? 'Entrando...' : 'Entrar' }
                     </button>
                  </form>
               </div>
            </div>
         </div>
      </div>
   )
}

const mapStateToProps = (state) => {
   const { loading, error } = state.Auth;
   return { loading, error };
};

export default connect(mapStateToProps, { loginUser })(Login);


