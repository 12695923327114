import api from './Api'

function validToken () {
	const Token =  localStorage.getItem("token")
	return Token
}


class HelpersBags {
   async ListBagsSizes() {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.get('bag_size/list', {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (bags)
			} catch (error) {
				reject (error)
			}
		})
	}

	async EditBagSize(id, values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.put('/admin/bag_size/'+id, values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async EstoqueBags(id, page) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.get('/admin/bag/list?branch_id='+id+"&page="+page, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async EstoqueBagsBranchs(page) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.get('/branch/bag/list?page='+page, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			   reject (error)
		   }
		})
	}

   async AddBag(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/admin/bag/add', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async AddBagBranch(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/branch/bag/add', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async EditBag(values, id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/admin/bag/'+id+'/edit', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async EditBagBranch(values, id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/branch/bag/'+id+'/edit', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async DeleteBag(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.delete('/admin/bag/'+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async DeleteBagBranch(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.delete('/branch/bag/'+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async sendBag(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/branch/bookings/'+id+'/status/in_use', {}, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async receiveBag(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const bags = await api.post('/branch/bookings/'+id+'/status/finish', {}, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*',
                  'Authorization': 'Bearer ' + validToken()
					},
				})
			   resolve (bags)
		   } catch (error) {
			  reject (error)
		   }
		})
	}


}

export { HelpersBags };