import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED, 
    UPDATE_USER,
    STATES,
    SET_USER,
} from './constants';


export const loginUser = (email, password) => ({
    type: LOGIN_USER,
    payload: { email, password }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const registerUser = (user) => ({
    type: REGISTER_USER,
    payload: { user }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const updateUser = (image) => ({
    type: UPDATE_USER,
    payload: { image }
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: { user }
});

export const setStates = (states) => ({
    type: STATES,
    payload: { states }
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error
});