/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, useParams } from "react-router-dom";

// Estilização Grid Propostas
import '../../components/components.css'

import PopUpHeader from '../../components/popUpHeader'

import Sucess from '../../components/sucess';

import FormColaborators from './components/formAdmin'



import { HelpersAuth, HelpersCollaborators } from '../../../../../helpers/index'
const helpersAuth = new HelpersAuth()
const helpersCollaborators = new HelpersCollaborators()


function Branchs() {
   const [open, setOpen] = useState(false);
   
   const [admins, setAdmins] = useState([])
   const [step, setStep] = useState('edit');
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false)
   const [leadLoading, setLeadLoading] = useState(false)
   const [error, setError] = useState('')
   const navigate = useNavigate();
   const { id } = useParams();
   
   useEffect(() => {
      if(id === undefined) {
         setOpen(false);
         preListAdmins()
         setStep('step0')
         setLeadLoading(false)
      }else {
         setStep('edit')
         setOpen(true);
      }
   }, [id])

   const preListAdmins = () => {
      setLoading(true)
      helpersCollaborators.LitsAdmins().then(resp => {
         setLoading(false)
         setAdmins(resp.data.data)
      })
   }

   const createAdmin = (values) => {
      setSaveLoading(true)
     
      helpersAuth.CreateUser(values).then(resp => {
         setSaveLoading(false)
         setStep('sucess')
      }).catch((error) => {
         setSaveLoading(false)
         setError(error.message)
         setTimeout(() => {
            setError('')
         }, 3000);
      })
   }

   const ModalAdd = () => {
      if(!open) return null;

      const handleClose = (e) => {
         if(e.target.id === 'wrapper') {
            if (id === undefined){
               setOpen(false);
               preListAdmins()
               setStep('step0')
               setLeadLoading(false)
            }
            navigate('/admin/panel/admins')
         }
      } 

      const renderScreen =  {
         'step0':  
            <FormColaborators
               header={<PopUpHeader title={'Cadastrar Admin'} subTitle={'Preencha os dados do Admin'}/>} 
               editSaveAdmin={(values) => createAdmin(values)}
               saveLoading={saveLoading}
               error={error}
            />,
         'sucess':  
            <Sucess 
               close={() => { 
                  if (id === undefined){
                     setOpen(false);
                     preListAdmins()
                     setStep('step0')
                     setLeadLoading(false)
               }
               navigate('/admin/panel/admins')}}
            >Admin criado com sucesso
         </Sucess>
      }

      return (
         <div 
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col">
               <button className="text-white text-xl place-self-end bg-transparent border-0" 
                  onClick={() => {
                     if (id === undefined){
                        setOpen(false);
                        preListAdmins()
                        setStep('step0')
                        setLeadLoading(false)
                     }
                     navigate('/admin/panel/admins')
                  }}>
                     X
               </button>
               <div className="bg-white p-2 rounded">
                  {
                     leadLoading ?  
                        <form  className='w-[300px] lg:w-[600px] xl:w-[1000px]' >
                           <section className="flex flex-row">
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                              </div>
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                    <Skeleton style={{width: "100%"}} height={35} />
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </form>
                     : renderScreen[step]
                  }
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap"> 
               <Skeleton height={20}/> 
            </td>
         </tr>
      )
   }

   return (
      <div className='pl-5 pr-5'>
         <div className='flex-row justify-between w-full h-[100px] flex items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Admins</h2>
            <a 
               href="#"
               onClick={() => setOpen(true)}
               className="h-10 text-xs font-bold uppercase 
               tracking-normal bg-default-orange text-white
               rounded-2xl flex justify-center items-center px-5"
            >
               Adicionar Admin
            </a>
         </div>
         <div className='overflow-y-scroll rounded-lg shadow h-[80%]'>
            <table className='w-full'>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-32 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>NOME</th>
                     <th className='w-24 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>EMAIL</th>
                     <th className='w-16 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'></th>
                  </tr>
               </thead>
               
               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ? 
                        (rows.map((item) => {
                           return (item)
                        })) : (admins.map((item, index) => {
                           return(
                              <tr className='bg-white' key={index}>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-bold text-blue-500">
                                   
                                    {item.id}
                                  
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                    {item.name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {item.email}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {/*<button onClick={() => {
                                       setColaborator(item);
                                       navigate(`/admin/panel/admins/${item.id}`)
                                    }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                       Editar
                                    </button>*/}
                                 </td>
                              </tr> 
                           )
                        })
                     ) 
                  }
               </tbody>
            </table>
         </div>
         <ModalAdd />
      </div>   
   )
}

export default Branchs