import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import DetailsBooking from './components/detailsBooking';
import PaginatedItems from "../../../../../components/pagination"
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { formataCPF,  } from '../../../../../utils';
import { statusPayment } from "../../../../../components/renderStatusPayment"
import  StatusBooking  from "../../.././../../components/renderStatusBooking"

import '../../components/components.css'

import { HelpersAuth, HelpersBookings } from '../../../../../helpers/index'

const helpersBookings = new HelpersBookings()
const helpersAuth = new HelpersAuth()

function Bookings({ branchs, branch, setBranch }) {
   const [open, setOpen] = useState(false);
   const [bookings, setBookings] = useState([]);
   const [loading, setLoading] = useState(true);
   const [booking, setBooking] = useState({})
   const [leadLoading, setLeadLoading] = useState(false)
   const [records, setRecords] = useState(0)
   const [cpf, setCpf] = useState('')  
   const [status, setStatus] = useState('')  
   const [startDate, setStartDate] = useState('')  
   const [endDate, setEndDate] = useState('')  
   const idBranchs = branchs.map((e) => {return e.id})
   const navigate = useNavigate();

   useEffect(() => {
      const listBookings = async (page = 1) => {
         setLoading(true)
   
         let stDate = startDate.length > 0 ?  moment(startDate).format('DD/MM/YYYY') : ""
         let enDate = endDate.length > 0 ?  moment(endDate).format('DD/MM/YYYY') : ""
   
         await helpersBookings.ListBookings(
            branch, 
            page, 
            cpf, 
            status, 
            stDate,
            enDate
         ).then((resp) => {
            setRecords(resp.data.total)
            Promise.all(resp.data.data.map(async (item) =>
            ({
               ...item,
               user: await findUser(item.user_id),
               name_branch: idBranchs.includes(item.branch_id) ? branchs.find(el => el.id === item.branch_id).name : 'Un excluída',
            })
            )).then((newProductList) => {
               setBookings(newProductList)
               setLoading(false);
            });
         }).catch((err) => {
            setLoading(false);
            setBookings([])
            setRecords(0)
         })
      }

      if(branchs.length > 0) listBookings()
   }, [branch, branchs, cpf, endDate,  startDate, status])

   const listBookings = async (page = 1) => {
      setLoading(true)

      let stDate = startDate.length > 0 ? moment(startDate).format('DD/MM/YYYY') : ""
      let enDate = endDate.length > 0 ? moment(endDate).format('DD/MM/YYYY') : ""

      await helpersBookings.ListBookings(
         branch, 
         page, 
         cpf, 
         status, 
         stDate,
         enDate
      ).then((resp) => {
         setRecords(resp.data.total)
         Promise.all(resp.data.data.map(async (item) =>
         ({
            ...item,
            user: await findUser(item.user_id),
            name_branch: idBranchs.includes(item.branch_id) ? branchs.find(el => el.id === item.branch_id).name : 'Un excluída',
         })
         )).then((newProductList) => {
            setBookings(newProductList)
            setLoading(false);
         });
      }).catch((err) => {
         setLoading(false);
         setBookings([]);
         setRecords(0);
      })
   }

   const getBooking = (id) => {
      setLeadLoading(true)
      helpersBookings.Booking(id).then(resp => {
         setBooking(resp.data.data);
         setLeadLoading(false)
      })
   }

   const findUser = async (usrId) => {
      const user = await helpersAuth.User(usrId).then(resp => {
         return resp.data.data[0]
      })
      let usr = await user
      return usr
   }

   const ModalAdd = () => {
      if (!open) return null;

      const handleClose = (e) => {
         if (e.target.id === 'wrapper') {
            setOpen(false);
            setBooking({})
            setLeadLoading(false)
            navigate('/admin/panel/reservas')
         }
      }

      return (
         <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm
            flex justify-center items-center" id="wrapper" onClick={handleClose}
         >
            <div className="max-w-[80%] mx-auto flex flex-col max-h-[98%]">
               <button className="text-white text-xl place-self-end bg-transparent border-0"
                  onClick={() => {
                     setOpen(false);
                     setBooking({})
                     setLeadLoading(false)
                     navigate('/admin/panel/reservas')
                  }}>
                  X
               </button>
               <div className="bg-white p-2 rounded overflow-y-scroll">
                  {
                     leadLoading ?
                        <form className='w-[300px] lg:w-[600px] xl:w-[1000px]' >
                           <section className="flex flex-row">
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                              </div>
                              <div className="basis-1/2 p-3">
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <div className="flex flex-col mb-5">
                                       <Skeleton style={{ width: "100%" }} height={50} />
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </form>
                     : <DetailsBooking booking={booking} />
                  }
               </div>
            </div>
         </div>
      )
   }

   let rows = []
   for (let i = 0; i < 15; i++) {
      rows.push(
         <tr className='bg-white h-1' key={i}>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
            <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
               <Skeleton height={20} />
            </td>
         </tr>
      )
   }

   return (
      <div className='px-5'>
         <div className='flex flex-row justify-between w-full h-[100px] items-center pr-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Reservas</h2>
         </div>
         <p>Filtros:</p>
         <div className='flex mb-3 space-x-3'>
            <select 
               name="color" 
               className="bg-gray-50 border border-gray-300 
               text-gray-900 text-sm rounded-lg outline-none
               focus:border-default-red block w-full p-2.5"
               autoComplete="nope" 
               required
               value={branch}
               onChange={(e) => setBranch(e.target.value)} 
            >  
               <option value="">TODAS AS UNIDADES</option>
               {
                  branchs.map((item, index) => {
                     return(
                        <option value={item.id} key={index.toString()}>{item.name} - {item.state_name}</option>
                     )
                  })
               }
            </select>
            <input
               type="text"
               name="password"
               id="password"
               placeholder='CPF do cliente'
               value={formataCPF(cpf)}
               maxLength={14}
               onChange={(e) => setCpf(e.target.value)}
               className="bg-gray-50 border border-gray-300 
               text-gray-900 text-sm rounded-lg outline-none
               focus:border-default-red block w-full p-2.5"
            />    
            <select 
               name="status" 
               className="bg-gray-50 border border-gray-300 
               text-gray-900 text-sm rounded-lg outline-none
               focus:border-black  w-full p-2"
               autoComplete="nope" 
               maxLength={10}
               value={status}
               onChange={(e) => setStatus(e.target.value)} 
            >
               <option value="">Selecione o status</option>
               <option value="pending">Pendente</option>
               <option value="scheduled">Faturado</option>
               <option value="in_delivery">Entregar</option>
               <option value="in_use">Em viagem</option>
               <option value="in_devolution">Devolver</option>
               <option value="finished">Finalizada</option>
            </select>
            <input
               type="date"
               name="password"
               id="password"
               value={startDate}
               onChange={(e) => setStartDate(e.target.value)}
               className="bg-gray-50 border border-gray-300 
               text-gray-900 text-sm rounded-lg outline-none
               focus:border-default-red block w-full p-2.5"
            />
            <h4 className='self-center'>até</h4>
            <input
               type="date"
               name="password"
               id="password"
               value={endDate}
               onChange={(e) => setEndDate(e.target.value)}
               className="bg-gray-50 border border-gray-300 
               text-gray-900 text-sm rounded-lg outline-none
               focus:border-default-red block w-full p-2.5"
            />   
            { /*<button 
               className='bg-default-orange px-2 rounded text-white'
               onClick={() => listBookings()}
            >
               Filtrar
            </button> */}
         </div>
         <div className='rounded-lg shadow overflow-auto'>
            <table className='w-full '>
               <thead className="bg-gray-50 border-b-2 border-gray-200 sticky top-0">
                  <tr>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>ID</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>UNIDADE</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>CLIENTE</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>DATA DE CRIAÇÃO</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>RECEBER</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>DEVOLVER</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>VALOR</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>PAGAMENTO</th>
                     <th className='w-1/9 p-3 text-sm font-semibold tracking-wide text-left text-[12px]'>STATUS</th>
                  </tr>
               </thead>

               <tbody className="propostas-table divide-y divide-gray-100 overflow-y-hidden">
                  {
                     loading ?
                        (rows.map((item) => {
                           return (item)
                        })) : (bookings.map((item, index) => {
                           return (
                              <tr className='bg-white overflow-auto' key={index}>
                                 <td className="p-3 text-sm font-semibold text-blue-500 whitespace-nowrap">
                                    <button onClick={() => {
                                       getBooking(item.id)
                                       setOpen(true);
                                       navigate(`/admin/panel/reservas/${item.id}`)
                                    }}
                                       className="font-bold text-blue-500 hover:underline"
                                    >
                                    {item.id}
                                    </button>
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap font-semibold">
                                    {item.name_branch}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {item.user.name}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(item.created_at).format('ll')}        
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(item.check_in).format('DD/MM/YY')}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {moment(item.check_out).format('DD/MM/YY')}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    R$ {item.total_value}
                                 </td>
                                 <td className="flex p-3 text-sm text-gray-700 whitespace-nowrap">
                                    {statusPayment(item.payment_method, item.status)}
                                 </td>
                                 <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                    <StatusBooking  status={item.reserve_status} />
                                 </td>
                              </tr>
                           )
                        })
                     )
                  }
               </tbody>
            </table>
         </div>
         <div className='flex flex-row justify-between w-full h-[100px] items-center '>
            <p className='text-slate-400 text-sm italic'>Resultados: {records}</p>
            <PaginatedItems itemsPerPage={15} setPg={(page) => listBookings(page)} qtdItems={records}/>
         </div>
         <ModalAdd />
      </div>
   )
}

export default Bookings