import React from "react";

const EditInfluencer = () => {
    return(
        <div>feliz</div> 
    )
   
}

export default EditInfluencer
