import api from './Api'

function validToken () {
	const Token =  localStorage.getItem("token")
	return Token
}

class HelpersBookings {
    async ListBookings(id, page, cpf, status, startDate, endDate) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('/admin/bookings/getAll?branch_id='+id+'&page='+page+'&start_date='+startDate+'&end_date='+endDate+'&status='+status+'&cpf='+cpf, {
					headers: {
					   'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async ListBookingsBranch(page, cpf, status, startDate, endDate) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const branch = await api.get('/branch/bookings?page='+page+'&cpf='+cpf+'&start_date='+startDate+'&end_date='+endDate+'&status='+status, {
					headers: {
					   'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (branch)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

	async Booking(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const booking = await api.get('/admin/bookings/'+id+'/details', {
					headers: {
					   'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (booking)
		   } catch (error) {
			  reject (error)
		   }
		})
	}

   async BookingBranch(id) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const booking = await api.get('/branch/bookings/'+id+'/details', {
					headers: {
					   'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (booking)
		   } catch (error) {
			  reject (error)
		   }
		})
	}
}

export { HelpersBookings };