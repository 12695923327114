import api from './Api'

function validToken() {
	const Token = localStorage.getItem("token")
	return Token
}

class HelpersAuth {

	async Login(email, password) {
		return new Promise(async(resolve, reject) => {
			const payload = {
				email: email,
				password : password
			}
  
		   try {
			   const auth = await api.post('auth/login', payload, {
					headers: {
					   'Content-Type': 'application/json',
					   'Access-Control-Allow-Origin' : '*'
					},
				})

			   resolve (auth)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

   async CreateUser(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const user = await api.post('/admin/user/create', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (user)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

   async CreateUserBranch(values) {
		return new Promise(async(resolve, reject) => {
		   try {
			   const user = await api.post('branch/user/create', values, {
					headers: {
					   'Content-Type': 'application/json',
					   'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (user)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

   async User(id) {
	
		return new Promise(async(resolve, reject) => {
		   try {
			   const user = await api.get('/admin/user/getAll?user_id='+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (user)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

	async UserB(id) {
	
		return new Promise(async(resolve, reject) => {
		   try {
			   const user = await api.get('/branch/user?user_id='+id, {
					headers: {
					   'Content-Type': 'application/json',
					   'Authorization': 'Bearer ' + validToken()
					},
				})

			   resolve (user)
		   } catch (error) {
			  reject (error.response.data)
		   }
		})
	}

}

export { HelpersAuth };
