/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const images_manual = [
  require('../../../../../assets/pop/1.jpg'),
  require('../../../../../assets/pop/2.jpg'),
  require('../../../../../assets/pop/3.jpg'),
  require('../../../../../assets/pop/4.jpg'),
  require('../../../../../assets/pop/5.jpg'),
  require('../../../../../assets/pop/6.jpg'),
  require('../../../../../assets/pop/7.jpg'),
  require('../../../../../assets/pop/8.jpg'),
  require('../../../../../assets/pop/9.jpg'),
  require('../../../../../assets/pop/10.jpg'),
  require('../../../../../assets/pop/11.jpg'),
  require('../../../../../assets/pop/12.jpg'),
  require('../../../../../assets/pop/13.jpg'),
  require('../../../../../assets/pop/14.jpg'),
  require('../../../../../assets/pop/15.jpg'),
  require('../../../../../assets/pop/16.jpg'),
  require('../../../../../assets/pop/17.jpg'),
  require('../../../../../assets/pop/18.jpg'),
  require('../../../../../assets/pop/19.jpg'),
  require('../../../../../assets/pop/20.jpg'),
  require('../../../../../assets/pop/21.jpg')
]

function Pop() {
  return (
    <div className='px-5'>
        <div className='flex-row justify-between w-full h-[100px] flex items-center '>
            <h2 className='text-default-blue font-bold text-[16px]'>.: Manual do licenciado</h2>
            <a
              href="/Manual.pdf"
              download="Manual do licenciado - Malug 2023"
              target="_blank"
              rel="noreferrer"
              className="h-10 text-xs font-bold uppercase 
                tracking-normal bg-default-orange text-white
                rounded-2xl flex justify-center items-center px-5"
              >
              baixar manual
            </a>
        </div>

        {
          images_manual.map((item, index) => {
            return (
              <img src={item} key={index.toString()} alt='' className='w-screen mb-5 rounded'/>
            )
          })
        }
    </div>
  )
}

export default Pop