


export const payment = (value) => {
    switch (value) {
       case 'pix':
          return (
            <div className="flex">
               <img 
                  src={require("../assets/images/pix.png")} 
                  width="15" 
                  height="20"
                  alt='img'
               /> 
               
            </div>
             
          )
 
       case 'credit_card':
          return (
            <div className="flex">
               <img 
                  src={require("../assets/images/card.png")} 
                  width="15" 
                  alt='img'
               /> 
               
            </div>
          )
    
       default:
       return (
             <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                Recusado
             </span>
          )
    }
 }