import React, { useState } from 'react'
import { valid } from '../../../../../../utils' 

function Step1({header, editBag, saveLoading, bag}) {
   const [name, setName] = useState((valid(bag.name) ? bag.name : '' ));
   const [price, setPrice] = useState((valid(bag.price) ? bag.price : '' ));
   const [secure, setSecure] = useState((valid(bag.secure_value) ? bag.secure_value : '' ));

   const next = (e) => {
      e.preventDefault();
      let data = {
         name: name,
         price: price,
         secure_value: secure,
      }
      editBag(data)
   }

   return (
      <form  autoComplete="off" className='w-[300px] lg:w-[600px] xl:w-[1000px]' onSubmit={next}>
         {header}
         <section className="flex flex-row">
               <div className="basis-1/2 p-3">
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                           <label className="text-xs font-medium uppercase mb-1">Nome da mala<span className="text-red-600">*</span></label>
                           <input type="text" name="nome" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2" 
                              autoComplete="nope" 
                              maxLength="100" autoFocus required 
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                           />
                     </div>
                  </div>
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                           <label className="text-xs font-medium uppercase mb-1">Preço da mala <span className="text-red-600">*</span></label>
                           <input  type="text" name="email" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2"
                              autoComplete="nope" maxLength="60" required 
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                           />
                     </div>
                  </div>
               </div>
               <div className="basis-1/2 p-3">
                  <div className="form-group">
                     <div className="flex flex-col mb-5">
                           <label className="text-xs font-medium uppercase mb-1">Preço da seguro da mala <span className="text-red-600">*</span></label>
                           <input  name="nascimento" 
                              className="border border-gray-300 
                              text-sm rounded outline-none
                              focus:border-black  w-full p-2"
                              autoComplete="nope" required
                              maxLength={10}
                              value={secure}
                              onChange={(e) => setSecure(e.target.value)} 
                           />
                     </div>
                  </div>
               </div>
         </section>
         <section className="flex justify-end p-3">
            <button 
               type='submit'
               className="w-24 h-10 text-xs font-bold uppercase 
                  tracking-wider bg-default-blue text-white
                  rounded flex justify-center items-center"
                  disabled={saveLoading}
               >
                  {saveLoading ? 'Salvando...' : 'Salvar'}
            </button>
         </section>
      </form>
   )
}

export default Step1