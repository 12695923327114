import React from "react"

function StatusBooking({status, sendBag, receiveBag}) {
   const statusBook = {
      'pending': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-gray-400 rounded-lg bg-opacity-50">
         Pendente 
      </span>,
      'scheduled': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
         faturado
      </span>,
      'in_delivery': <button 
         className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 
         bg-violet-200 hover:bg-blue-200  rounded-lg bg-opacity-50"
         onClick={sendBag}
      >
        Entregar
      </button>,
      'in_use': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-blue-200 rounded-lg bg-opacity-50">
         Em viagem
      </span>,
      'in_devolution': <button 
         className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 
         bg-orange-200 hover:bg-red-200 rounded-lg bg-opacity-50"
         onClick={receiveBag}
      >
         Devolver
      </button>,
      'finished': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
         Finalizada
      </span>
   }
      
   return statusBook[status] 
}

export default StatusBooking