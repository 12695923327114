import React from 'react'

function Dashboard() {
   return (
      <div>
         <div className='flex flex-row justify-between w-full h-24 flex items-center px-7'>
            <h2 className='text-default-blue font-bold text-[16px]'>.:Dashboard</h2>
         </div>
      </div>
   )
}

export default Dashboard
