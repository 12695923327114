export const statusBag = (value) => {
   const statusBags = {
      'available':  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
         disponível
      </span>,
      'in_use': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-blue-800 bg-blue-200 rounded-lg bg-opacity-50">
         Em uso
      </span>,
      'unavailable': <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
         indisponível
      </span>
   }
     
   return statusBags[value] 
}