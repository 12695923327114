import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';
import Sidebar from './sidebar';

import AdminDashboard from './admin/itens/Dashboard';
import AdminTamMala from './admin/itens/SizeBag';
import AdminFranquias from './admin/itens/Branchs';
import AdminAdmins from './admin/itens/Admins';
import AdminCollaboradores from './admin/itens/Collaborators';
import AdminReservas from './admin/itens/Bookings';
import AdminEstoque from './admin/itens/Stock';
import AdminInfluencers from './admin/itens/Influencers';

import BranchDashboard from './branch/itens/Dashboard';
import BranchCollaboradores from './branch/itens/Collaborators';
import BranchReservas from './branch/itens/Bookings';
import BranchEstoque from './branch/itens/Stock';
import Pop from './branch/itens/Pop'

import { findState, states } from '../../utils' 

import { connect } from "react-redux";

import { logoutUser } from '../../redux/actions'

import { HelpersBranchs } from '../../helpers/index'
const helpersBranchs = new HelpersBranchs()

const Panel = (props) => {
   const { rota, logoutUser, user } = props;
   const [screen, setScreen] = useState('dashboard');
   const [branchs, setBranchs] = useState([]);
   const [branch, setBranch] = useState('');

   const navigate = useNavigate();

   useEffect(() => {
      setScreen(rota)
   }, [rota])

   useEffect(() => {
      const listBranchs = async() => {
         helpersBranchs.LitsBranchs().then((resp) => {
            Promise.all(resp.data.data.map(async (item) => ({
               ...item,
               state_name: findState(states, item.state_id),
               })
            )).then((newProductList) => {
               setBranchs(newProductList)
            });
         })
      }

      user.profile_type === "admin" && listBranchs()
   }, [user.profile_type])

   const renderScreenAdmin = {
      'dashboard': <AdminDashboard />,
      'collaborators': <AdminCollaboradores branchs={branchs} branch={branch} setBranch={e => setBranch(e)} />,
      'bags': <AdminTamMala />,
      'branchs': <AdminFranquias states={states} />,
      'estoque': <AdminEstoque branchs={branchs} branch={branch} setBranch={e => setBranch(e)} />,
      'admins': <AdminAdmins />,
      'reservas': <AdminReservas branchs={branchs} branch={branch} setBranch={e => setBranch(e)} />,
      'influencers': <AdminInfluencers states={states} />,
      'manualdolicenciado': <Pop />
   }

   const renderScreenBranch = {
      'dashboard': <BranchDashboard />,
      'collaborators': <BranchCollaboradores user={user} />,
      'estoque': <BranchEstoque user={user} />,
      'reservas': <BranchReservas user={user} />,
      'manualdolicenciado': <Pop />,
   }

   return (
      <Sidebar user={user} screen={screen} branchs={branchs} logout={() => logoutUser(navigate)}>
         {user.profile_type === 'branch' ? renderScreenBranch[screen] : renderScreenAdmin[screen]}
      </Sidebar>
   );
};

const mapStateToProps = (state) => {
   const { loading, error, states, user } = state.Auth;
   return { loading, error, states, user };
};

export default connect(mapStateToProps, { logoutUser })(Panel);