import React, { useState, useEffect } from "react";
import moment from 'moment';

import { formatarTelefone } from "../../../../../../utils";

import { HelpersAdress } from '../../../../../../helpers'
const helpersAdress = new HelpersAdress()

const AddInfluencer = ({ header, editSaveInfluencer, saveLoading, states, error }) => {
   const [name, setName] = useState('')
   const [instagram, setInstagram] = useState('');
   const [tikTok, setTikTok] = useState('');
   const [whatsApp, setWhatsApp] = useState('');
   const [stateInfluencer, setStateInfluencer] = useState('');
   const [citys, setCitys] = useState([]);
   const [cityInfluencer, setCityInfluencer] = useState('');

   useEffect(() => {
      findCity(stateInfluencer)
   }, [stateInfluencer])
   

   const findCity = async(sta) => {
      await helpersAdress.ListCities(sta).then(resp => {
         setCitys(resp.data.data)
      })
   } 

   const next = (e) => {
      e.preventDefault();
      editSaveInfluencer({
         name: name,
         instagram: instagram,
         whatsapp: whatsApp,
         state_id: stateInfluencer,
         city_id: cityInfluencer
      })
   }

   return (
      <form autoComplete="off" className='w-[300px] lg:w-[600px] xl:w-[1000px]' onSubmit={next}>
         {header}
         <section className="flex flex-row">
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Nome<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="Marca"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                        autoComplete="nope"
                        placeholder='Nome'
                        maxLength="100"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Tik Tok</label>
                     <input
                        type="text"
                        name="purchaseDate"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black w-full p-2"
                        autoComplete="nope"
                        placeholder='TikTok'
                        maxLength="100"
                        value={tikTok}
                        onChange={(e) => setTikTok(e.target.value)}
                     />
                  </div>
               </div>   
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Estado<span className="text-red-600">*</span></label>
                     <select  
                        name="nascimento" 
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope" 
                        required
                        value={stateInfluencer}
                        onChange={(e) => setStateInfluencer(e.target.value)} 
                     >
                        <option value="">Selecione o estado</option>
                        {
                           states.map((item, index) => 
                              <option value={item.id} key={index.toString()}>{item.name}</option>
                           )
                        }
                     </select>
                  </div>
               </div>
            </div>
            <div className="basis-1/2 p-3">
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">Instagram<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="modelo"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        placeholder='Instagram'
                        required
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                     <label className="text-xs font-medium uppercase mb-1">WhatsApp<span className="text-red-600">*</span></label>
                     <input
                        type="text"
                        name="modelo"
                        className="border border-gray-300 
                        text-sm rounded outline-none
                        focus:border-black  w-full p-2"
                        autoComplete="nope"
                        placeholder='WhatsApp'
                        required
                        maxLength={14}
                        value={formatarTelefone(whatsApp)}
                        onChange={(e) => setWhatsApp(e.target.value)}
                     />
                  </div>
               </div>
               <div className="form-group">
                  <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium uppercase mb-1">Cidade <span className="text-red-600">*</span></label>
                        <select 
                           name="cidade" 
                           className="border border-gray-300 
                           text-sm rounded outline-none
                           focus:border-black  w-full p-2"
                           autoComplete="nope" 
                           maxLength="60" 
                           required 
                           value={cityInfluencer}
                           onChange={(e) => setCityInfluencer(e.target.value)}
                        >  
                           <option value="">Selecione a cidade</option>
                           {
                              citys.map((item, index) => 
                                 <option value={item.id} key={index.toString()}>{item.name}</option>
                              )
                           }
                        </select>
                  </div>
               </div>
            </div>
         </section>
         {
            error ? <div className='bg-red-300 rounded-lg p-2 mx-3'>
               <p className="text-red-600">
                  Erro ao cadastrar influencer
               </p>
            </div> : null
         }
         <section className="flex justify-end p-3">
            <button
               type='submit'
               className="w-24 h-10 text-xs font-bold uppercase 
                  tracking-wider bg-default-blue text-white
                  rounded flex justify-center items-center"
               disabled={saveLoading}
            >
               {saveLoading ? 'Salvando...' : 'Salvar'}
            </button>
         </section>
      </form>
   )
}

export default AddInfluencer
