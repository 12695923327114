export const valid = (prop) => {
    let isValid = true
    if(prop === undefined || prop === null){
        isValid = false
    }
    return isValid
}

export const fomataRg = (v) => {
    v=v.replace(/\D/g,"");
    v=v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/,"$1.$2.$3-$4");
    return v;
}

export const formataData = (v) => {
    v=v.replace(/\D/g,"");
    v=v.replace(/(\d{2})(\d)/,"$1/$2") 
    v=v.replace(/(\d{2})(\d)/,"$1/$2") 
    return v;  
}

export const formataCPF = (v) => {
    v=v.replace(/\D/g,"");
    v=v.replace(/(\d{3})(\d)/,"$1.$2");
    v=v.replace(/(\d{3})(\d)/,"$1.$2");
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    return v;
} 

export const formatarTelefone = (v) => {
    v=v.replace(/\D/g,"");
    v=v.replace(/^(\d\d)(\d)/g,"($1)$2");
    v=v.replace(/(\d{5})(\d)/,"$1-$2");    
    return v;
}

export const formatarCep = (v) => {
    v=v.replace(/\D/g,"")                
    v=v.replace(/^(\d{5})(\d)/,"$1-$2") 
    return v;
}

export const findState = (estados, id = 10) => {
    let sta;
    estados.forEach(element => {
       if(element.id === id) sta = element.uf;
    });
    return sta
} 

export const setCity = (cities = [], id) => {
    let cti;
    cities.forEach(element => {
        if(element.id === id) cti = element.name
    });
    return cti
}

export const findFreight = (freight = [], id) => {
    let cti = {}
    freight.forEach(element => {
        if(element.city_id === id) cti = element
    });
    return cti
}

export const banks = [
    {
      "nome": "Banco do Brasil",
      "codigo": "001"
    },
    {
      "nome": "Caixa Econômica Federal",
      "codigo": "104"
    },
    {
      "nome": "Bradesco",
      "codigo": "237"
    },
    {
      "nome": "Itaú Unibanco",
      "codigo": "341"
    },
    {
      "nome": "Santander",
      "codigo": "033"
    },
    {
      "nome": "Nubank",
      "codigo": "260"
    },
    {
      "nome": "C6 Bank",
      "codigo": "336"
    },
    {
      "nome": "Banco Inter",
      "codigo": "077"
    },
    {
      "nome": "Banco Original",
      "codigo": "212"
    },
    {
      "nome": "Banco Digio",
      "codigo": "263"
    },
    {
      "nome": "Mercado Pago",
      "codigo": "323"
    }
]

export const states = [
  {
      "id": 1,
      "name": "Acre",
      "uf": "AC",
      "ibge": "12",
      "ddd": "68",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 2,
      "name": "Alagoas",
      "uf": "AL",
      "ibge": "27",
      "ddd": "82",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 3,
      "name": "Amazonas",
      "uf": "AM",
      "ibge": "13",
      "ddd": "97,92",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 4,
      "name": "Amapá",
      "uf": "AP",
      "ibge": "16",
      "ddd": "96",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 5,
      "name": "Bahia",
      "uf": "BA",
      "ibge": "29",
      "ddd": "77,75,73,74,71",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 6,
      "name": "Ceará",
      "uf": "CE",
      "ibge": "23",
      "ddd": "88,85",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 7,
      "name": "Distrito Federal",
      "uf": "DF",
      "ibge": "53",
      "ddd": "61",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 8,
      "name": "Espírito Santo",
      "uf": "ES",
      "ibge": "32",
      "ddd": "28,27",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 9,
      "name": "Goiás",
      "uf": "GO",
      "ibge": "52",
      "ddd": "62,64,61",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 10,
      "name": "Maranhão",
      "uf": "MA",
      "ibge": "21",
      "ddd": "99,98",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 11,
      "name": "Minas Gerais",
      "uf": "MG",
      "ibge": "31",
      "ddd": "34,37,31,33,35,38,32",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 12,
      "name": "Mato Grosso do Sul",
      "uf": "MS",
      "ibge": "50",
      "ddd": "67",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 13,
      "name": "Mato Grosso",
      "uf": "MT",
      "ibge": "51",
      "ddd": "65,66",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 14,
      "name": "Pará",
      "uf": "PA",
      "ibge": "15",
      "ddd": "91,94,93",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 15,
      "name": "Paraíba",
      "uf": "PB",
      "ibge": "25",
      "ddd": "83",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 16,
      "name": "Pernambuco",
      "uf": "PE",
      "ibge": "26",
      "ddd": "81,87",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 17,
      "name": "Piauí",
      "uf": "PI",
      "ibge": "22",
      "ddd": "89,86",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 18,
      "name": "Paraná",
      "uf": "PR",
      "ibge": "41",
      "ddd": "43,41,42,44,45,46",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 19,
      "name": "Rio de Janeiro",
      "uf": "RJ",
      "ibge": "33",
      "ddd": "24,22,21",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 20,
      "name": "Rio Grande do Norte",
      "uf": "RN",
      "ibge": "24",
      "ddd": "84",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 21,
      "name": "Rondônia",
      "uf": "RO",
      "ibge": "11",
      "ddd": "69",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 22,
      "name": "Roraima",
      "uf": "RR",
      "ibge": "14",
      "ddd": "95",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 23,
      "name": "Rio Grande do Sul",
      "uf": "RS",
      "ibge": "43",
      "ddd": "53,54,55,51",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 24,
      "name": "Santa Catarina",
      "uf": "SC",
      "ibge": "42",
      "ddd": "47,48,49",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 25,
      "name": "Sergipe",
      "uf": "SE",
      "ibge": "28",
      "ddd": "79",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 26,
      "name": "São Paulo",
      "uf": "SP",
      "ibge": "35",
      "ddd": "11,12,13,14,15,16,17,18,19",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  },
  {
      "id": 27,
      "name": "Tocantins",
      "uf": "TO",
      "ibge": "17",
      "ddd": "63",
      "created_at": "2023-08-04T11:32:45.000000Z",
      "updated_at": "2023-08-04T11:32:45.000000Z"
  }
]

export const bag_sizes = (id) => {
    const bagSises = {
        1: "pequena",
        2: "média",
        3: "grande"
    }
    return bagSises[id]
}